import {tag_prefix, tag_suffix, tag_type_prefix_unsubscribe} from 'Config/app';

/** @typedef {string} TagLengthPrecision */
export const TAG_LENGTH_PRECISION_AVERAGE = 'AVERAGE';
export const TAG_LENGTH_PRECISION_PRECISE = 'EXACT';
/** @enum {TagLengthPrecision} */
export const TAG_LENGTH_PRECISION = {
	AVERAGE: TAG_LENGTH_PRECISION_AVERAGE,
	PRECISE: TAG_LENGTH_PRECISION_PRECISE
};
/** @type {TagLengthPrecision[]} */
export const TAG_LENGTH_PRECISIONS = [
	TAG_LENGTH_PRECISION_AVERAGE,
	TAG_LENGTH_PRECISION_PRECISE
];

/** @typedef {string} Tag */
export const TAG_FIRST_NAME = `${tag_prefix}First Name${tag_suffix}`;
export const TAG_FIRST_NAME_ASCII = `${tag_prefix}First Name ASCII${tag_suffix}`;
export const TAG_LAST_NAME = `${tag_prefix}Last Name${tag_suffix}`;
export const TAG_LAST_NAME_ASCII = `${tag_prefix}Last Name ASCII${tag_suffix}`;
export const TAG_EMAIL = `${tag_prefix}Email${tag_suffix}`;
export const TAG_PHONE_NUMBER = `${tag_prefix}Phone number${tag_suffix}`;
export const TAG_UNSUBSCRIBE_CLIENT = `${tag_prefix}${tag_type_prefix_unsubscribe}Client${tag_suffix}`;
export const TAG_UNSUBSCRIBE_CLIENT_MESSAGE_TYPE = `${tag_prefix}${tag_type_prefix_unsubscribe}Client & message type${tag_suffix}`;
export const TAG_UNSUBSCRIBE_PROJECT = `${tag_prefix}${tag_type_prefix_unsubscribe}Project${tag_suffix}`;
export const TAG_UNSUBSCRIBE_PROJECT_MESSAGE_TYPE = `${tag_prefix}${tag_type_prefix_unsubscribe}Project & message type${tag_suffix}`;
export const TAG_START_TRACKING_LINKS = `${tag_prefix}Start tracking links${tag_suffix}`;
export const TAG_STOP_TRACKING_LINKS = `${tag_prefix}Stop tracking links${tag_suffix}`;
/** @enum {Tag} */
export const TAG = {
	FIRST_NAME: TAG_FIRST_NAME,
	FIRST_NAME_ASCII: TAG_FIRST_NAME_ASCII,
	LAST_NAME: TAG_LAST_NAME,
	LAST_NAME_ASCII: TAG_LAST_NAME_ASCII,
	EMAIL: TAG_EMAIL,
	PHONE_NUMBER: TAG_PHONE_NUMBER,
	UNSUBSCRIBE_CLIENT: TAG_UNSUBSCRIBE_CLIENT,
	UNSUBSCRIBE_CLIENT_MESSAGE_TYPE: TAG_UNSUBSCRIBE_CLIENT_MESSAGE_TYPE,
	UNSUBSCRIBE_PROJECT: TAG_UNSUBSCRIBE_PROJECT,
	UNSUBSCRIBE_PROJECT_MESSAGE_TYPE: TAG_UNSUBSCRIBE_PROJECT_MESSAGE_TYPE,
	START_TRACKING_LINKS: TAG_START_TRACKING_LINKS,
	STOP_TRACKING_LINKS: TAG_STOP_TRACKING_LINKS
};
/** @type {Tag[]} */
export const TAGS = [
	TAG_FIRST_NAME,
	TAG_FIRST_NAME_ASCII,
	TAG_LAST_NAME,
	TAG_LAST_NAME_ASCII,
	TAG_EMAIL,
	TAG_PHONE_NUMBER,
	TAG_UNSUBSCRIBE_CLIENT,
	TAG_UNSUBSCRIBE_CLIENT_MESSAGE_TYPE,
	TAG_UNSUBSCRIBE_PROJECT,
	TAG_UNSUBSCRIBE_PROJECT_MESSAGE_TYPE,
	TAG_START_TRACKING_LINKS,
	TAG_STOP_TRACKING_LINKS
];

/**
 * Tags guaranteed to be ASCII
 * @type {Tag[]}}
 */
export const ASCII_TAGS = [
	TAG_FIRST_NAME_ASCII, 
	TAG_LAST_NAME_ASCII, 
	TAG_EMAIL, 
	TAG_PHONE_NUMBER, 
	TAG_UNSUBSCRIBE_CLIENT, 
	TAG_UNSUBSCRIBE_CLIENT_MESSAGE_TYPE, 
	TAG_UNSUBSCRIBE_PROJECT,
	TAG_UNSUBSCRIBE_PROJECT_MESSAGE_TYPE,
	TAG_START_TRACKING_LINKS,
	TAG_STOP_TRACKING_LINKS,
];