import "./index.css";

import * as campaignPageConfig from "Pages/apps/default/projectPages/campaign/config";
import * as cappingPageConfig from "Pages/apps/default/projectPages/capping/config";

import React from "react";
import ProjectPageComponent from "../ProjectPageComponent";
import * as appConfig from "../../config";
import * as pageConfig from "./config";
import {NavLink, withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import {selectors} from "Core/store/reducers";
import {getPageActions} from "Core/helpers/redux";
import {getMenuSidebarShrankFromStorage} from "Layout/elements/MainSidebar/helpers";
import {getCurrentProjectFromUrl} from 'Helpers/project';
import {getObject, getString, isset, trimArray} from 'Core/helpers/data';
import Label from 'Core/components/display/Label';
import {
	CAMPAIGN_MESSAGE_CONTENT_TYPE_ICONS,
	CAMPAIGN_MESSAGE_CONTENT_TYPES
} from 'Pages/apps/default/projectPages/campaign/const';
import Icon from 'Core/components/display/Icon';
import CampaignWizardDialog from 'Components/dialogs/CampaignWizardDialog';
import {rtrimChar} from 'Core/helpers/string';
import {waitingFunction} from 'Core/helpers/function';
import {getRouterPathUrl} from 'Core/helpers/url';
import {openMainSidebarGroupAction} from 'Layout/elements/MainSidebar';
import {
	createCampaignItemAction,
	createMessageDefinitionItemAction, 
	updateMessageDefinitionItemAction
} from 'Pages/apps/default/projectPages/campaign/actions';
import {find} from 'lodash';
import Acl from 'Acl/projects';

// Imported subpages
const subPages = [
	campaignPageConfig,
	cappingPageConfig	
];

/**
 * Redux 'mapStateToProps' function
 *
 * @param {Object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	mainSidebarShrank: getMenuSidebarShrankFromStorage(selectors.mainSidebar.shrank(state)),
	projectList: selectors.projectSelector.getProjectList(state),
});

class DashboardPage extends ProjectPageComponent {
	constructor(props) {
		super(props, {
			domPrefix: 'dashboard-page',
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
			renderTitle: false,
		}, 'page_title');

		// Dialog methods
		this.openCampaignWizardDialog = this.openCampaignWizardDialog.bind(this);
	}


	// Dialog methods ---------------------------------------------------------------------------------------------------
	/**
	 * Open a dialog for creating new campaigns using a simplified wizard interface
	 * @type {CampaignMessageContentType} messageType - Type of message to create campaign for.
	 */
	openCampaignWizardDialog(messageType) {
		const {
			projectList, location, openDialogAction, createCampaignItemAction, createMessageDefinitionItemAction,
			updateMessageDefinitionItemAction, addSuccessMessageAction, closeDialogAction, 
		} = this.props;
		const currentProject = getCurrentProjectFromUrl(projectList, location);

		const dialogGUIID = openDialogAction('', CampaignWizardDialog, {
			messageType,
			project: currentProject,
			/** @param {CampaignWizardDataObject} data */
			onSave: data => {
				const project = find(this.getProp('projectList'), {id: data.projectId});
				
				// Create campaign
				this.executeAbortableAction(createCampaignItemAction, data, project)
					.then(
						/**
						 * @param {CampaignItemDataObject} createdCampaign
						 * @return {Promise<void>}
						 */
						async createdCampaign => {
							if (!isset(createdCampaign)) return;

							// Create a message definition for the newly created campaign
							/** @type {MessageDefinitionDataObject} */
							const createdMessageDefinition = await this.executeAbortableAction(
								createMessageDefinitionItemAction, createdCampaign.id
							);
							if (!isset(createdMessageDefinition)) return;

							// Update message definition with basic data and add channels
							/** @type {MessageDefinitionDataObject} */
							const updatedMessageDefinition = await this.executeAbortableAction(
								updateMessageDefinitionItemAction, {
									...createdMessageDefinition,
									startDate: data.startDate,
									channels: data.channels,
								}
							);
							if (!isset(updatedMessageDefinition)) return;

							closeDialogAction(dialogGUIID);

							// Redirect to the newly created campaign
							this.redirectTo(
								`${rtrimChar(appConfig.routerPath, '/')}/project/${data.projectId}/campaign/` +
								`${createdCampaign.id}/#wizard`
							);

							// Open edit popup for the newly created messaged definition in wizard mode and open recipients tab
							waitingFunction(() => {
								const mdElem = document.getElementById(`md-${updatedMessageDefinition.id}`);
								if (!!mdElem) {
									mdElem.click();
									return true;
								}
							}, 1, 60000)
								.then(() => waitingFunction(() => {
									const tabButtonElem = document.querySelector(
										'#message-definition-popup .popup-tab.RecipientsTab'
									);
									if (!!tabButtonElem) {
										tabButtonElem.click();
										return true;
									}
								}, 1, 60000));

							addSuccessMessageAction(this.t(
								'create_success_msg', 'AppsSection.DefaultApp.projectPages.CampaignPage'
							));
						});
			},
		}, {
			id: 'campaign-wizard-dialog',
			className: 'bordered-title',
			closeOnEscape: true,
			closeOnClickOutside: false,
			hideCloseBtn: false,
			maxWidth: 650
		});
		this.setOption(
			'dialogsToCloseOnUnmount',
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
		);
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {mainSidebarShrank, toggleMainSidebarSizeAction} = this.props;
		const {projectList, location} = this.props;
		const currentProject = getCurrentProjectFromUrl(projectList, location);
		const title = getString(currentProject, 'name');
		const currentProjectId = getString(currentProject, 'id');
		const allowedSubPages = (
			!!currentProjectId ? subPages.filter(page => Acl.check(Acl, currentProjectId, page.access)) : []
		);
		const canEdit = (
			!!currentProjectId ? Acl.checkPermission(Acl, currentProjectId, ['CAMPAIGN_WRITE', 'CAMPAIGN_PUBLISH']) : false
		);
		
		return this.renderLayout((
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')}`}>
				{
					this.hasTranslation('page_short_description') && this.t('page_short_description') ?
						<div className="simple-page-description">
							<Label content={this.t('page_short_description')} supportHtml={true} />
						</div>
						: null
				}

				<div className="project-hero">
					<div className="project-icon"><Icon symbol="cube"/></div>
					{this.projectExists() && title ?
						<h1 className="project-title"><Label content={this.translate(title, this.titlePathPrefix)}/></h1> 
						: null
					}
				</div>

				{canEdit ?
					<div className="quick-campaign-wrapper card style-highlight">
						<div className="card-content">
							<h2 className="page-subtitle"><Label content={this.t('create_campaign_title')}/></h2>
							<div className="simple-subtitle-description">
								<Label content={this.t('create_campaign_subtitle')}/>
							</div>
							<div className="card-grid per-row-5">
								{CAMPAIGN_MESSAGE_CONTENT_TYPES.map(p =>
									<div key={p} className="card action-card-small no-select">
										<div className="card-content" onClick={() => this.openCampaignWizardDialog(p)}>
											<Icon {...CAMPAIGN_MESSAGE_CONTENT_TYPE_ICONS[p]} />
											<p className="no-margin"><Label content={this.t(p, 'constants.messageType')}/></p>
										</div>
									</div>
								)}

								<div className="card action-card-small no-select soon">
									<div className="card-ribbon"><Label content={this.t('comingSoon', 'general')}/></div>
									<div className="card-content">
										<Icon symbol="whatsapp"/>
										<p className="no-margin"><Label content={this.t('WHATSAPP', 'constants.messageType')}/>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					: null
				}

				<div className="all-pages-wrapper">
					<h2 className="page-subtitle"><Label content={this.t('sub_pages_title')}/></h2>
					<div className="simple-subtitle-description"><Label content={this.t('sub_pages_desc')}/></div>
					<div className="card-grid per-row-5">
						{allowedSubPages.map(subPage =>
							<NavLink
								key={subPage.routerPath}
								to={
									// Replace router path :projectId with the value form session storage (cookie)
									getRouterPathUrl(
										getRouterPathUrl(subPage.routerPath, getObject(this.props, 'match')),
										{params: {projectId: getString(currentProject, 'id')}}
									)
								}
								onClick={() => {
									const {openMainSidebarGroupAction} = this.props;
									
									// Open parent group in the main sidebar menu
									const projectGroupRootElem = document.querySelector(
										`.group-menu-item > [data-project-id="${getString(currentProject, 'id')}"]`
									);
									if (!!projectGroupRootElem) openMainSidebarGroupAction(projectGroupRootElem.id);
								}}
								className="card action-card no-select"
							>
								<div className="card-content">
									{subPage.iconElement}
									<Label element="p" content={this.t('page_link_label', subPage.translationPath)}/>
								</div>
							</NavLink>
						)}
					</div>
				</div>
			</div>
		), undefined, undefined, {
			app: appConfig,
			mainSidebarShrank,
			toggleMainSidebarSizeAction
		});
	}
}

export * from "./config";
export default withRouter(connect(mapStateToProps, getPageActions({
	openMainSidebarGroupAction, createCampaignItemAction, createMessageDefinitionItemAction, 
	updateMessageDefinitionItemAction
}))(DashboardPage));