import {getStorageValue} from 'Core/storage';
import {acl_check_mode, acl_storage_type, acl_storage_var, acl_super_permission, acl_super_user_type} from 'Config/acl';
import {AclCheckDataObject, AclDataObject} from 'Core/acl';
import {arrayIncludes, arrayIncludesAny, getArray, getBool, getObject, getString, isset} from 'Core/helpers/data';
import auth from '../auth';

/**
 * Projects specific access-control list (ACL) class
 */
class ProjectsACL {
	/**
	 * Check if current user is a super user
	 * @note This method combines checking for super user permission and type. It uses the OR method to check meaning
	 * that it will pass if either one of them is super.
	 *
	 * @param {CoreACL|ACL} [_this=this] - Current class in the class tree. This is used when extending the CoreACL class
	 * and overwriting this method, to make sure that other overwritten child methods will be used properly.
	 * @param {string} projectId - DB ID of the project.
	 * @return {boolean}
	 */
	static isSuper(_this = this, projectId) {
		return (_this.isSuperUserType(_this, projectId) || _this.hasSuperPermission(_this, projectId));
	}
	
	/**
	 * Get current ACL data from storage
	 * @param {string} projectId - DB ID of the project.
	 * @return {AclDataObject}
	 */
	static get(projectId) {
		try {
			const data = JSON.parse(getStorageValue(acl_storage_var, acl_storage_type));
			const projectsData = getObject(auth.getCurrentUser(), 'projectPermissionsMap');
			
			return new AclDataObject(
				getArray(projectsData, projectId),
				getString(data, 'userType'),
				getBool(data, 'isGuest'),
			);
		} catch (e) {
			return new AclDataObject();
		}
	}

	/**
	 * Check access rights
	 * @note This method will combine user permission, type and guest status checks. It will pass only if all checks
	 * pass.
	 *
	 * @param {CoreACL|ACL} [_this=this] - Current class in the class tree. This is used when extending the CoreACL class
	 * and overwriting this method, to make sure that other overwritten child methods will be used properly.
	 * @param {string} projectId - DB ID of the project.
	 * @param {AclCheckDataObject} [access] - ACL check rules.
	 * @return {boolean}
	 */
	static check(_this = this, projectId, access = new AclCheckDataObject()) {
		return (
			_this.isSuper(_this, projectId) ||
			(
				_this.checkPermission(_this, projectId, access.permissions) &&
				_this.checkUserType(_this, projectId, access.userTypes) &&
				(isset(access.forGuest) ? _this.checkForGuest(_this, projectId, access.forGuest) : true)
			)
		);
	}


	// Permissions ------------------------------------------------------------------------------------------------------
	/**
	 * Get permissions from storage
	 * @note Storage field and type is defined in 'acl_storage_var' and 'acl_storage_type' acl config options.
	 *
	 * @param {CoreACL|ACL} [_this=this] - Current class in the class tree. This is used when extending the CoreACL class
	 * and overwriting this method, to make sure that other overwritten child methods will be used properly.
	 * @param {string} projectId - DB ID of the project.
	 * @return {string[]} Returns an array of permissions or an empty array if permissions are not defined, or they
	 * cannot be parsed.
	 */
	static getPermissions(_this = this, projectId) {
		try {
			return _this.get(projectId).permissions;
		} catch (e) {
			return [];
		}
	}

	/**
	 * Check if super permission is available in storage
	 *
	 * @param {CoreACL|ACL} [_this=this] - Current class in the class tree. This is used when extending the CoreACL class
	 * and overwriting this method, to make sure that other overwritten child methods will be used properly.
	 * @param {string} projectId - DB ID of the project.
	 * @return {boolean}
	 */
	static hasSuperPermission(_this = this, projectId) {
		return _this.getPermissions(_this, projectId).includes(acl_super_permission);
	}

	/**
	 * Check if access is allowed based on the specified permissions
	 * @note This method checks permissions using a method defined in 'acl_check_mode' acl config option.
	 *
	 * @param {CoreACL|ACL} [_this=this] - Current class in the class tree. This is used when extending the CoreACL class
	 * and overwriting this method, to make sure that other overwritten child methods will be used properly.
	 * @param {string} projectId - DB ID of the project.
	 * @param {string[]} [permissions=[]] - List of permissions to check access for.
	 * @return {boolean}
	 */
	static checkPermission(_this = this, projectId, permissions = []) {
		// Allow access if required permission are empty or not defined
		if (!permissions || permissions.length === 0) return true;
		
		// Allow access if current user has super permission
		if (_this.hasSuperPermission(_this, projectId)) return true;
		
		try {
			const availablePermissions = _this.getPermissions(_this, projectId);
			if (availablePermissions.length) {
				switch (acl_check_mode) {
					case 'AND': return arrayIncludes(availablePermissions, permissions);
					case 'OR': return arrayIncludesAny(availablePermissions, permissions);
					default: {
						console.error(
							"Project ACL check mode config option is invalid or undefined! Please check the value of " +
							"'acl_check_mode' in acl config file."
						);
						return false;
					}
				}
			} else {
				console.error("Project ACL check failed! Available permissions are empty or undefined.");
				return false;
			}
		} catch (e) {
			console.error(
				"Project ACL check failed! Could not parse available permissions. Check if permissions are present in " +
				"storage define by 'projects_acl_storage_var' and 'projects_acl_storage_type' config options.", e
			);
			return false;
		}
	}


	// User types -------------------------------------------------------------------------------------------------------
	/**
	 * Get user type from storage
	 * @note Storage field and type is defined in 'acl_storage_var' and 'acl_storage_type' acl config options.
	 *
	 * @param {CoreACL|ACL} [_this=this] - Current class in the class tree. This is used when extending the CoreACL class
	 * and overwriting this method, to make sure that other overwritten child methods will be used properly.
	 * @param {string} projectId - DB ID of the project.
	 * @return {string} Returns the current user type or an empty string.
	 */
	static getUserType(_this = this, projectId) {
		try {
			return _this.get(projectId).userType;
		} catch (e) {
			return '';
		}
	}

	/**
	 * Check if current user has a super user type
	 * @note Super user type is defined in 'acl_super_user_type' acl config option.
	 *
	 * @param {CoreACL|ACL} [_this=this] - Current class in the class tree. This is used when extending the CoreACL class
	 * and overwriting this method, to make sure that other overwritten child methods will be used properly.
	 * @param {string} projectId - DB ID of the project.
	 * @return {boolean}
	 */
	static isSuperUserType(_this = this, projectId) {
		return (_this.getUserType(_this, projectId) === acl_super_user_type);
	}

	/**
	 * Check if access is allowed based on the specified user types
	 * @note Since user can have only one user type (by design), 'userTypes' list will be checked using the OR method
	 * meaning that it will pass if current user type is found in the 'userTypes' list.
	 *
	 * @param {CoreACL|ACL} [_this=this] - Current class in the class tree. This is used when extending the CoreACL class
	 * and overwriting this method, to make sure that other overwritten child methods will be used properly.
	 * @param {string} projectId - DB ID of the project.
	 * @param {string[]} [userTypes=[]] - List of user types to check access for.
	 * @return {boolean}
	 */
	static checkUserType(_this = this, projectId, userTypes = []) {
		// Allow access if required user types are empty or not defined
		if (!userTypes || userTypes.length === 0) return true;

		// Allow access for super user type
		if (_this.isSuperUserType(_this, projectId)) return true;

		return userTypes.includes(_this.getUserType(_this, projectId));
	}


	// Guest ------------------------------------------------------------------------------------------------------------
	/**
	 * Check if current user is a guest user
	 *
	 * @param {CoreACL|ACL} [_this=this] - Current class in the class tree. This is used when extending the CoreACL class
	 * and overwriting this method, to make sure that other overwritten child methods will be used properly.
	 * @param {string} projectId - DB ID of the project.
	 * @return {boolean}
	 */
	static isGuest(_this = this, projectId) {
		return _this.get(projectId).isGuest;
	}

	/**
	 * Check if access should be allowed for guest
	 * @note This method will return true if current user is not guest and if 'forGuest' is not defined.
	 *
	 * @param {CoreACL|ACL} [_this=this] - Current class in the class tree. This is used when extending the CoreACL class
	 * and overwriting this method, to make sure that other overwritten child methods will be used properly.
	 * @param {string} projectId - DB ID of the project.
	 * @param {boolean} [forGuest] - Specify access right for guest user. If not specified, true will be returned.
	 * @return {boolean}
	 */
	static checkForGuest(_this = this, projectId, forGuest) {
		if (isset(forGuest)) return (_this.isGuest(_this, projectId) ? forGuest : true);
		else return true;
	}
}

export default ProjectsACL;