import React from "react";
import BaseComponent, {executeComponentCallback} from 'Core/components/BaseComponent';
import {connect} from 'react-redux';
import {getPageActions} from 'Core/helpers/redux';
import SelectInput from 'Core/components/input/SelectInput';
import {find, omit} from 'lodash';
import PropTypes from 'prop-types';
import {CAMPAIGN_MESSAGE_CONTENT_TYPES} from 'Pages/apps/default/projectPages/campaign/const';
import {getArray, getString, getStringForDisplay, isset} from 'Core/helpers/data';
import {icon_font_loading_symbol} from 'Config/app';
import Label from 'Core/components/display/Label';

/**
 * Get all actions used by this component
 * @type {Object}
 */
const allActions = getPageActions();

class ChannelSelectInput extends BaseComponent {
	constructor(props) {
		super(props);

		this.state = {
			/** @type {ChannelListItemDataObject[]|null|undefined} */
			channelList: null,
			/** @type {boolean} */
			channelListLoading: false,
		};

		// Data methods
		this.loadOptions = this.loadOptions.bind(this);
	}

	/** @inheritDoc */
	async asyncComponentDidMount() {
		await super.asyncComponentDidMount();

		// Load select options
		await this.loadOptions();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		// Re-load select options and clear the value if it does not exist in the list when certain props change
		if (
			this.props.clientId !== prevProps.clientId ||
			this.props.projectId !== prevProps.projectId ||
			this.props.messageType !== prevProps.messageType
		) {
			this.loadOptions()
				// Clear the value if it does not exist in the list
				.then(() => {
					const channel = find(getArray(this.state, 'channelList'), {value: this.getProp('value')});
					if (!isset(channel)) executeComponentCallback(this.props.onChange, null);
				});
		}
	}

	/**
	 * Load select options
	 * @param {boolean} [onMount=false] - Specifies if this method is called as part of the component mount cycle.
	 * @return {Promise<void>}
	 */
	async loadOptions(onMount = false) {
		const {clientId, projectId, messageType, fetchChannelsCodebookAction} = this.props;
		const executeAbortableAction = (onMount ? this.executeAbortableActionMount : this.executeAbortableAction);
		
		await this.setState({channelListLoading: true})
			.then(() => executeAbortableAction(fetchChannelsCodebookAction, projectId, messageType, clientId))
			.then(channelList => this.setState({channelList, channelListLoading: false}))
			.finally(() => this.setState({channelListLoading: false}));
	}
	
	render() {
		const {additionalOptionsAbove, additionalOptionsBelow, value, readOnly} = this.props;
		const {channelList, channelListLoading} = this.state;
		const options = !!channelList ?
			[
				...getArray(additionalOptionsAbove),
				...channelList,
				...getArray(additionalOptionsBelow),
			]
			:
			[
				...getArray(additionalOptionsAbove),
				...getArray(additionalOptionsBelow),
			];
		const selectValue = find(options, {value});
		
		return (
			!readOnly ?
				<SelectInput
					{...omit(this.props, [
						'clientId', 'projectId', 'messageType', 'additionalOptionsAbove', 'additionalOptionsBelow', 
						'readOnly',
						...Object.keys(allActions)
					])}
					options={options}
					isLoading={channelListLoading}
				/>
				:
				<Label
					icon={channelListLoading ? icon_font_loading_symbol : undefined}
					iconSpin={true}
					content={getStringForDisplay(getString(selectValue, 'label'))}
				/>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
ChannelSelectInput.propTypes = {
	...SelectInput.propTypes,

	clientId: PropTypes.string,
	projectId: PropTypes.string,
	messageType: PropTypes.oneOf([...CAMPAIGN_MESSAGE_CONTENT_TYPES, '']),
	// Options to add above the list of projects in the select options dropdown
	additionalOptionsAbove: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string,
	})),
	// Options to add below the list of projects in the select options dropdown
	additionalOptionsBelow: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string,
	})),
	// Flag that determines if component will be rendered in read-only mode (only selected option label will be rendered)
	readOnly: PropTypes.bool,
};

/**
 * Define component default values for own props
 */
ChannelSelectInput.defaultProps = {
	...SelectInput.defaultProps,
};

export default connect(null, allActions, null, {forwardRef: true})(ChannelSelectInput);