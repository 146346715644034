import {
	ALL_GSM_7_CHARSET_EXTENSION_REGEXP,
	HAS_NON_GSM_7_CHARSET_REGEXP,
	SMS_ENCODING,
	SMS_MAX_SIZE_BYTES,
	SMS_UDH_SIZE_BYTES
} from './const';
import {getArray} from 'Core/helpers/data';
import {getTagsUsedInContent, isAsciiTag} from 'Tags/helpers';

/**
 * Calculate the length of the SMS content by replacing all tags with fill values with the length defined for the tag
 * 
 * @param {any} [content] - SMS message content.
 * @param {TagDataObject[]} tags - All the tags supported in the SMS message content.
 * @param {SmsEncoding} encoding - SMS message content encoding to get the size for.
 * @return {number}
 */
export const calculateSmsLength = (content, tags, encoding) => {
	if (!content || typeof content !== 'string') return 0;

	// Replace all tags with fill values with the length defined for the tag
	let contentReplacedTags = content;
	getArray(tags).forEach(
		/** @param {TagDataObject} tag */
		tag => { contentReplacedTags = contentReplacedTags.replace(tag.tag, Array(tag.length + 1).join('x')); });
	
	// Count characters in GMS-7 extension charset as two
	if (encoding === SMS_ENCODING.GSM7) {
		return contentReplacedTags.replace(ALL_GSM_7_CHARSET_EXTENSION_REGEXP, 'xx').length;
	}
	
	return contentReplacedTags.length;
};

/**
 *
 * @param {any} [content] - SMS message content.
 * @param {TagDataObject[]} tags - All the tags supported in the SMS message content.
 * @return {?SmsEncoding} where null means that encoding cannot be calculated.
 */
export const calculateSmsEncoding = (content, tags) => {
	// If tags are not yet loaded (when null), there is no way to determine the encoding so return null
	if (tags === null) return null;
	
	// Set default encoding if content value is not specified or is not a string
	if (!content || typeof content !== 'string') return SMS_ENCODING.GSM7;

	// Replace all tags with fill values with the length defined for the tag
	// @note This is done so that checking for non-GMS-7 characters will not check tag names. Tags will be checked 
	// separately later in the function.
	let contentReplacedTags = content;
	getArray(tags).forEach(
		/** @param {TagDataObject} tag */
		tag => { contentReplacedTags = contentReplacedTags.replace(tag.tag, Array(tag.length + 1).join('x')); });
	
	// First check if there are non-GSM-7 characters in the content since if there are, there is no need for checking
	// anything else because the message must be encoded as UCS-2/UTF-16
	if (HAS_NON_GSM_7_CHARSET_REGEXP.test(contentReplacedTags ?? '')) return SMS_ENCODING.UCS2_UTF16;
	
	/**
	 * All tags used in the content
	 * @type {TagDataObject[]}
	 */
	const usedTags = getTagsUsedInContent(content, tags);

	// If no tags were used in the content, encoding is undoubtedly GMS-7 (since we previously checked that there are no 
	// non-GSM-7 characters in the content)
	if (usedTags.length === 0) return SMS_ENCODING.GSM7;
	
	// If every used tag is guaranteed to be ASCII, encoding is undoubtedly GMS-7
	if (usedTags.every(isAsciiTag)) return SMS_ENCODING.GSM7;
	// If there is a used tag that is not guaranteed to be ASCII, encoding cannot be determined, return null
	else return null;
};

/**
 * Calculate the number of SMS message parts for the given content
 * @note This function takes into account tags and the length of tag values. Some tag values have precise length and 
 * some have average since their values depend on user data (personalization tags, customer custom field, etc.).
 * 
 * @param {string} content - Content of the message.
 * @param {TagDataObject[]} tags - List of all supported tags in content.
 * @param {SmsEncoding} encoding - SMS message content encoding to get the size for.
 * @return {?number} where null means that parts could not be calculated.
 */
export const calculateSmsParts = (content, tags, encoding) => {
	const length = calculateSmsLength(content, tags, encoding);
	
	let sizeInBytes;
	switch (encoding) {
		case SMS_ENCODING.GSM7:
			sizeInBytes = Math.ceil((length * 7) / 8);
			break;
			
		case SMS_ENCODING.UCS2_UTF16:
			sizeInBytes = length * 2;
			break;
			
		default:
			return null;
	}
	
	if (sizeInBytes <= SMS_MAX_SIZE_BYTES) return 1;
	return Math.ceil(sizeInBytes / (SMS_MAX_SIZE_BYTES - SMS_UDH_SIZE_BYTES));
};