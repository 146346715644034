import React from "react";
import ProjectPageDataComponent from 'Pages/apps/default/projectPages/ProjectPageDataComponent';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {getPageActions} from "Core/helpers/redux";
import {selectors} from "Core/store/reducers";
import * as appConfig from "../../config";
import * as pageConfig from "./config";
import * as actions from "./actions";
import {getMenuSidebarShrankFromStorage} from "Layout/elements/MainSidebar/helpers";
import {reducerStoreKey} from "./reducer";
import {areAllObjectPropsEmpty, getArray, getNumber, getString, isset, trimArray} from 'Core/helpers/data';
import * as filterDataMap from "./dataMap/filter";
import {scrollToSelector} from "Core/helpers/dom";
import {getPopupInstance} from "Core/helpers/popup";
import ItemPopup from "./popups/ItemPopup";
import {Tooltip} from "react-tippy";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import {icon_font_create_symbol, icon_font_edit_symbol, icon_font_info_circle_symbol} from 'Config/app';
import ConfirmDialog from "Core/components/dialogs/ConfirmDialog";
import Label from "Core/components/display/Label";
import SimpleStaticSearch, {
	SIMPLE_STATIC_SEARCH_DISPLAY_TYPE,
	SIMPLE_STATIC_SEARCH_LAYOUT,
	SimpleStaticSearchOptionObject
} from 'Core/components/advanced/SimpleStaticSearch';
import DataTable, {DATA_TABLE_CELL_TYPE} from "Core/components/advanced/DataTable";
import {PAGINATION_TYPE} from "Core/components/action/Pagination";
import {isSuccessful} from "Core/helpers/io";
import {AsyncMountError} from "Core/errors";
import {DataTableCellAnyTypeOptionsDataObject} from 'Core/components/advanced/DataTable/DataTableCell/dataObjects';
import {getCurrentProjectFromUrl, getCurrentProjectIdFromUrl} from 'Helpers/project';
import CampaignSelectInput from 'Components/input/CampaignSelectInput';
import Breadcrumbs from 'Core/components/display/Breadcrumbs';
import Acl from 'Acl/projects';

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	projectList: selectors.projectSelector.getProjectList(state),
	isMobileBreakpoint: selectors.breakpoint.isMobileBreakpoint(state),
	mainSidebarShrank: getMenuSidebarShrankFromStorage(selectors.mainSidebar.shrank(state)),
	mainList: selectors[reducerStoreKey].getCappingList(state),
	mainListPagination: selectors[reducerStoreKey].getCappingListPagination(state),
	mainListSort: selectors[reducerStoreKey].getCappingListSort(state),
	mainListFilter: selectors[reducerStoreKey].getCappingListFilter(state),
});

class CappingPage extends ProjectPageDataComponent {
	constructor(props) {
		super(props, {
			data: {
				/**
				 * Currently selected search filter
				 */
				filter: {},
				/**
				 * Flag showing if filter is loading
				 */
				filterLoading: false,

				/**
				 * Flag showing if page is loading data
				 * @type {boolean}
				 */
				loading: false,
			},

			/**
			 * Flag that specifies if main data table height will be limited to the available space
			 */
			limitToAvailableSpace: true
		}, {
			domPrefix: 'capping-page',
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
			checkLogin: false,
			disableLoad: true,
		}, 'page_title');

		// Refs
		this.mainListFilterRef = null;

		// Data methods
		this.reloadMainList = this.reloadMainList.bind(this);
		this.loadMainListPage = this.loadMainListPage.bind(this);
		this.sortMainList = this.sortMainList.bind(this);
		this.filterMainList = this.filterMainList.bind(this);
		this.removeMainListFilter = this.removeMainListFilter.bind(this);
		this.isMainListFilterEmpty = this.isMainListFilterEmpty.bind(this);

		// Popup methods
		this.openMainListItemPopup = this.openMainListItemPopup.bind(this);
		this.closeMainListItemPopup = this.closeMainListItemPopup.bind(this);
		this.handleMainListItemPopupClose = this.handleMainListItemPopupClose.bind(this);

		// Action methods
		this.deleteMainListItem = this.deleteMainListItem.bind(this);

		// Render methods
		this.renderActions = this.renderActions.bind(this);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		return super.componentDidUpdate(prevProps, prevState, snapshot)
			.then(() => {
				const prevProjectId = getString(getCurrentProjectIdFromUrl(prevProps.location));
				const projectId = getString(getCurrentProjectIdFromUrl(this.props.location));
				
				if (prevProjectId !== projectId) {
					const {resetCappingListAction} = this.props;

					// Clear all page data and load the page again
					resetCappingListAction();
					this.clear().then(() => this.loadPageData().catch(() => {}));
				}
			});
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called on component mount and should be used to load any data required by the page
	 * @return {Promise<any>}
	 * @throws {AsyncMountError}
	 */
	loadPageData() {
		const {mainList, loadCappingListAction, location} = this.props;

		// If main list was already loaded (user already opened the page before)
		if (isset(mainList)) {
			// Open filter if it is not empty
			if (!this.isMainListFilterEmpty() && this.mainListFilterRef) this.mainListFilterRef.open();
			// Reload main list with currently applied filter, sort and pagination
			return this.reloadMainList()
				.then(res => { if (!isset(res)) throw new AsyncMountError(); });
		}
		// Load main list if it is not already loaded
		else {
			return this.setValue('loading', true)
				.then(() => this.executeAbortableActionMount(loadCappingListAction, getCurrentProjectIdFromUrl(location)))
				.then(() => this.setValue('loading', false));
		}
	}

	/**
	 * Reload main list using current options (page, sort, ...)
	 * @return {Promise<*>}
	 */
	reloadMainList() {
		const {loadCappingListAction, mainListPagination, mainListSort, mainListFilter, location} = this.props;
		const {pageNo, perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const projectId = getCurrentProjectIdFromUrl(location);
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(loadCappingListAction, projectId, oFilter, pageNo, perPage, sortBy, sortDir)
			.then(res => {
				this.mainListFilterRef?.reload();
				return res;
			});
	}

	/**
	 * Reload main list using current options (page, sort, ...) if any
	 * @param {number} [pageNo=1] - Page number to load (starts with 1).
	 * @return {Promise<*>}
	 */
	loadMainListPage(pageNo = 1) {
		const {loadCappingListAction, mainListPagination, mainListSort, mainListFilter, location} = this.props;
		const {perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const projectId = getCurrentProjectIdFromUrl(location);
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(loadCappingListAction, projectId, oFilter, pageNo, perPage, sortBy, sortDir);
	}

	/**
	 * Sort main list
	 * @param {string} sortBy - Name of the sort column.
	 * @param {string} sortDir - Direction of the sort.
	 * @return {Promise<*>}
	 */
	sortMainList(sortBy, sortDir) {
		const {loadCappingListAction, mainListPagination, mainListFilter, location} = this.props;
		const {pageNo, perPage} = mainListPagination;
		const projectId = getCurrentProjectIdFromUrl(location);
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(loadCappingListAction, projectId, oFilter, pageNo, perPage, sortBy, sortDir);
	}

	/**
	 * Filter main list
	 * @param {Object} filter - Filter object where keys are filter field names and values are filter values.
	 * @return {Promise<*>}
	 */
	filterMainList(filter) {
		const {
			loadCappingListAction, setCappingFilterAction, mainListPagination, mainListSort, location,
		} = this.props;
		const {perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const projectId = getCurrentProjectIdFromUrl(location);
		const oFilter = filterDataMap.output(filter);
		
		// Set filter so that the change will be detected after IO
		setCappingFilterAction(filter);

		return this.setValue('filterLoading', true)
			.then(() => this.executeAbortableAction(
				loadCappingListAction, projectId, oFilter, 1, perPage, sortBy, sortDir
			))
			.then(() => this.setValue('filterLoading', false))
			.then(() => {
				if (areAllObjectPropsEmpty(oFilter, true, false)) {
					if (this.mainListFilterRef) {
						this.mainListFilterRef.close()
							.then(() => this.setState({limitToAvailableSpace: true}));
					}
				} else {
					scrollToSelector('#main-page-table', false, 80);
				}
			});
	}

	/**
	 * Remove main list filter
	 * @return {Promise<*>}
	 */
	removeMainListFilter() {
		return this.filterMainList(null);
	}

	/**
	 * Check if main list filter is applied
	 * @return {Boolean}
	 */
	isMainListFilterEmpty() {
		return areAllObjectPropsEmpty(this.getProp('mainListFilter'), true, false);
	}


	// Router methods ---------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called if current URL matches the 'create' sub-url of the page
	 * @note Create sub-url uses '/new' router path relative to the router path of the page (see 'options.routerPath').
	 *
	 * @param {Object} [prevLocation] - Previous router location.
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on
	 * 'create' sub-url if such component exists.
	 */
	handleCreateUrl(prevLocation) {
		const {location} = this.props;
		const currentProjectIdFromUrl = getCurrentProjectIdFromUrl(location);
		const canEdit = (
			!!currentProjectIdFromUrl ? 
				Acl.checkPermission(Acl, currentProjectIdFromUrl, ['FREQUENCY_CAPPING_PLAN_WRITE']) :
				false
		);
		
		if (canEdit) {
			// Open main list item popup if it is not already opened
			// @note This is done to avoid opening multiple dialogs when switching from item edit to new item URL. Dynamic URL
			// component data should be changes using the standard 'updateDynamics' method.
			if (!this.urlComponentGUIID) {
				return this.openMainListItemPopup(true);
			}
			// If URL component is opened, update its dynamic items (tabs, action buttons, ...)
			else {
				const mainListItemPopup = getPopupInstance(this.urlComponentGUIID);
				return mainListItemPopup.updateDynamics({isNew: true}).then(() => this.urlComponentGUIID);
			}
		} else {
			this.redirectToBase();
		}
	}

	/**
	 * Method that will be called if current URL matches the 'item' sub-url of the page
	 * @note Item sub-url uses '/item' router path and 'id' as router path param ('/item/:id') on top of to the router
	 * path of the page (see 'options.routerPath').
	 *
	 * @param {string|number} id - Item ID.
	 * @param {Object} [prevLocation] - Previous router location.
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on
	 * 'item' sub-url if such component exists.
	 */
	handleItemUrl(id, prevLocation) {
		const {loadCappingItemAction} = this.props;

		// @note This is done to avoid opening multiple dialogs when switching from new item to edit item URL. Dynamic URL
		// component data should be changes using the standard 'updateDynamics' method.
		if (!this.urlComponentGUIID) {
			this.urlComponentGUIID = this.openMainListItemPopup();
		}
		// If URL component is opened, update its dynamic items (tabs, action buttons, ...)
		else {
			const mainListItemPopup = getPopupInstance(this.urlComponentGUIID);
			mainListItemPopup.updateDynamics({isNew: false}).then();
		}

		// Try to load main list item
		return new Promise(resolve =>
			// Timeout is added to allow for the popup open animation to finish
			setTimeout(() => resolve(
				this.executeAbortableAction(loadCappingItemAction, id)
					.then(mainListItem => {
						// If main list item is successfully loaded
						if (mainListItem) {
							return this.urlComponentGUIID;
						}
						// If main list item could not be loaded (usually if item with ID from URL does not exist)
						else {
							// Close main list item popup if it is opened
							if (this.urlComponentGUIID) this.closeUrlComponent();
							// Redirect to page base url (removes main list item ID from URL if it exists)
							this.redirectToBase();
							return '';
						}
					})
			))
		);
	}

	/**
	 * Method that will be called if current URL matches the base URL of the page
	 *
	 * @param {Object} [prevLocation] - Previous router location.
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on its
	 * base URL if such component exists.
	 */
	handleBaseUrl(prevLocation) {
		this.closeMainListItemPopup();
		return '';
	}

	/**
	 * Method that will be called when page component unmounts and should handle closing of any page url or sub-url
	 * component if it exists.
	 */
	closeUrlComponent() {
		this.closeMainListItemPopup();
	}


	// Popup methods ----------------------------------------------------------------------------------------------------
	/**
	 * Open main list item popup
	 * @param {boolean} [isNew=false] - Flag that specifies if new main list item popup should be opened.
	 */
	openMainListItemPopup(isNew = false) {
		const {openPopupAction} = this.props;
		return openPopupAction(ItemPopup, {
			isNew,
			onClose: this.handleMainListItemPopupClose,
			deleteItemAction: this.deleteMainListItem,
			redirectToItem: this.redirectToItem,
			redirectToBase: this.redirectToBase,
		});
	}

	/**
	 * Close main list item popup
	 */
	closeMainListItemPopup() {
		const {closePopupAction, clearCappingItemAction} = this.props;

		// Close item popup
		const urlComponentGUIID = this.getUrlComponentGUIID();
		if (urlComponentGUIID) {
			closePopupAction(urlComponentGUIID);
			this.clearUrlComponentGUIID();
		}

		// Clear popup Redux data
		clearCappingItemAction();
	}

	/**
	 * Handle main list item popup 'onClose' event
	 * @return {void}
	 */
	handleMainListItemPopupClose() {
		this.clearUrlComponentGUIID();
		this.redirectToBase();
		// Timeout is added to allow for the popup close animation to finish
		setTimeout(this.reloadMainList);
	}


	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Delete main list item
	 * 
	 * @param {CappingListItemDataObject} item - Main list popup item.
	 * @return {Promise<void>}
	 */
	deleteMainListItem(item) {
		const {
			openDialogAction, closeDialogAction, deleteCappingItemAction, addSuccessMessageAction
		} = this.props;
		
		return new Promise(resolve => {
			const dialogGUIID = openDialogAction('', ConfirmDialog, {
				message: this.t('confirm_delete', '', '', {name: item.name}),
				supportHtml: true,
				onYes: () => {
					this.executeAbortableAction(deleteCappingItemAction, item.id)
						.then(response => {
							if (isSuccessful(response)) {
								addSuccessMessageAction(this.tt('delete_success_msg', 'ItemPopup'));
								// Reload main list data
								// @note This is done asynchronously on purpose because there is no reason to wait for this to 
								// finish before continuing.
								this.reloadMainList()
									// Go to the previous page if there are no table rows after one has been deleted
									.then(() => {
										const mainList = getArray(this.props, 'mainList');
										const pageNo = getNumber(this.props, 'mainListPagination.pageNo', 1);
										if (mainList.length === 0 && pageNo > 1) return this.loadMainListPage(pageNo - 1);
									});
							}
							return Promise.resolve(response);
						})
						.then(() => closeDialogAction(dialogGUIID))
						.finally(() => resolve('yes'));
				},
				onNo: () => {
					closeDialogAction(dialogGUIID);
					resolve('no');
				}
			}, {
				id: 'item-delete-dialog',
				closeOnEscape: true,
				closeOnClickOutside: true,
				hideCloseBtn: true,
				maxWidth: 500
			});
			this.setOption(
				'dialogsToCloseOnUnmount', 
				trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
			);
		});
	}
	

	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render data table actions cell
	 * @param {CappingListItemDataObject} row - Data table row.
	 * @return {JSX.Element}
	 */
	renderActions(row) {
		const {location} = this.props;
		const currentProjectIdFromUrl = getCurrentProjectIdFromUrl(location);
		const canEdit = (
			!!currentProjectIdFromUrl ? 
				Acl.checkPermission(Acl, currentProjectIdFromUrl, ['FREQUENCY_CAPPING_PLAN_WRITE']) :
				false
		);
		
		return (
			<div className="actions standard-actions-row">
				{/*<Tooltip*/}
				{/*	tag="div"*/}
				{/*	title={this.t('delete_tooltip')}*/}
				{/*	size="small"*/}
				{/*	position="top-center"*/}
				{/*	arrow={true}*/}
				{/*	interactive={false}*/}
				{/*>*/}
				{/*	<Button*/}
				{/*		className="action-btn no-border-radius"*/}
				{/*		displayStyle={BUTTON_STYLE.ACTION}*/}
				{/*		displayType={BUTTON_DISPLAY_TYPE.NONE}*/}
				{/*		icon={icon_font_delete_symbol}*/}
				{/*		onClick={() => this.deleteMainListItem(row)}*/}
				{/*	/>*/}
				{/*</Tooltip>*/}

				<Tooltip
					tag="div"
					title={canEdit ? this.t('edit_tooltip') : this.t('view_tooltip')}
					size="small"
					position="top-center"
					arrow={true}
					interactive={false}
				>
					<Button
						className="action-btn no-border-radius"
						displayStyle={BUTTON_STYLE.ACTION}
						displayType={BUTTON_DISPLAY_TYPE.NONE}
						icon={canEdit ? icon_font_edit_symbol : icon_font_info_circle_symbol}
						onClick={() => this.redirectToItem(row.id)}
					/>
				</Tooltip>
			</div>
		);
	}

	/**
	 * Render page title
	 * @description This method specifies how page title will be rendered if page title should be rendered. It does not
	 * determine if page title should be rendered.
	 * @return {JSX.Element}
	 */
	renderPageTitle() {
		const {location, projectList} = this.props;
		const {title} = this.state;
		/** @type {ProjectListItemDataObject} */
		const currentProject = getCurrentProjectFromUrl(projectList, location);
		const canEdit = (
			!!currentProject ? Acl.checkPermission(Acl, currentProject.id, ['FREQUENCY_CAPPING_PLAN_WRITE']) : false
		);

		return (
			<h1 className={`page-title with-actions ${!canEdit ? 'reload-only' : ''}`}>
				<div className="content">
					<Breadcrumbs
						includeHome={false}
						items={[
							{
								to: this.getProjectTo(),
								label: getString(currentProject, 'name')
							},
							{
								label: (title ? this.translate(title, this.titlePathPrefix) : '')
							}
						]}
					/>
				</div>
				<div className="actions">
					<div className="action-button">
						<Tooltip
							tag="div"
							title={this.t('Reload data', 'general')}
							size="small"
							position="top-center"
							arrow={true}
							interactive={false}
						>
							<Button
								big={true}
								icon="refresh"
								displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
								displayStyle={BUTTON_STYLE.SUBTLE}
								onClick={this.reloadMainList}
							/>
						</Tooltip>
					</div>

					{canEdit ?
						<div className="action-button main-button">
							<Link to={this.getCreateRedirectTo()} className="button action solid big">
								<Label icon={icon_font_create_symbol} content={this.t('create_new')}/>
							</Link>
						</div>
						: null
					}
				</div>
			</h1>
		);
	}

	render() {
		const {
			mainList, mainListPagination, mainListSort, mainListFilter, mainSidebarShrank, toggleMainSidebarSizeAction,
			location
		} = this.props;
		const {limitToAvailableSpace} = this.state;
		const projectId = getCurrentProjectIdFromUrl(location);
		
		return this.renderLayout((
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')}`}>
				{
					this.hasTranslation('page_short_description') && this.t('page_short_description') ?
						<div className="simple-page-description">
							<Label content={this.t('page_short_description')} supportHtml={true} />
						</div>
						: null
				}

				<SimpleStaticSearch
					styleName="compact"
					className="main-search"
					defaultCollapse={true}
					layout={SIMPLE_STATIC_SEARCH_LAYOUT.STACKED}
					buttonProps={{
						displayStyle: BUTTON_STYLE.DEFAULT
					}}
					options={[
						new SimpleStaticSearchOptionObject(
							'name',
							this.t('nameField')
						),
						new SimpleStaticSearchOptionObject(
							'isActive',
							this.t('activeField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
							{
								isClearable: true,
								options: [
									{label: this.tt('true', 'statusBoolean'), value: '1'},
									{label: this.tt('false', 'statusBoolean'), value: '0'},
								]
							}
						),
						new SimpleStaticSearchOptionObject(
							'campaignId',
							this.t('campaignIdField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.CUSTOM,
							{
								isClearable: true,
								projectId,
								useDefaultOptionFormat: true,
							},
							CampaignSelectInput,
						)
					]}
					value={mainListFilter}
					title={(<Label icon="search" content={this.t('Search', 'general')} />)}
					applied={!this.isMainListFilterEmpty()}
					enableToolbar={true}
					enableResetButton={false}
					onChange={this.filterMainList}
					onRemove={this.removeMainListFilter}
					onToggle={visible => this.setState({limitToAvailableSpace: visible})}
					ref={node => { this.mainListFilterRef = node; }}
				/>

				<DataTable
					id="main-page-table"
					className="standard sticky-last-column"
					limitToAvailableSpace={limitToAvailableSpace && !this.getProp('isMobileBreakpoint')}
					highlightOnHover={true}
					columns={[
						{
							name: 'active',
							sortName: 'active',
							label: this.t('activeField'),
							dataType: DATA_TABLE_CELL_TYPE.ANY,
							dataTypeOptions: new DataTableCellAnyTypeOptionsDataObject({
								content: row => (
									row.active ?
										<div className="tag style-success">{this.tt('true', 'statusBoolean')}</div> :
										<div className="tag default-success">{this.tt('false', 'statusBoolean')}</div>
								),
							}),
							width: 1
						},
						{
							name: 'name',
							sortName: 'name',
							label: this.t('nameField')
						},
						{
							name: 'campaignCount',
							label: this.t('campaignCountField'),
							dataType: DATA_TABLE_CELL_TYPE.NUMBER,
						},
						
						{
							dataType: DATA_TABLE_CELL_TYPE.ANY,
							dataTypeOptions: new DataTableCellAnyTypeOptionsDataObject({
								standardWrapper: false,
								content: this.renderActions,
							}),
							stopPropagation: true,
							width: 1,
						}
					]}
					onRowClick={data => this.redirectToItem(data.id)}
					data={mainList}
					paginationType={PAGINATION_TYPE.STATIC}
					onSortByColumn={this.sortMainList}
					onPaginationClick={this.loadMainListPage}
					{...mainListPagination}
					{...mainListSort}
				/>
			</div>
		), undefined, undefined, {
			app: appConfig,
			mainSidebarShrank,
			toggleMainSidebarSizeAction,
		});
	}
}

export * from "./config";
export default connect(mapStateToProps, getPageActions(actions))(CappingPage);