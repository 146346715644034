import styles from "./index.module.css";
import "./default.style.css";

import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import DataComponent, {executeComponentCallback} from "Core/components/DataComponent";
import {getBoolean, getString, isset} from "Core/helpers/data";
import Spinner from "Core/components/display/Spinner";
import {getCssSizeString, getVh, getViewportSize, getVw, vertScrollbarVisible} from "Core/helpers/dom";
import {ADVANCED_DROPDOWN_POSITION, ADVANCED_DROPDOWN_POSITIONS} from "Core/components/display/AdvancedDropdown/const";

class AdvancedDropdown extends DataComponent {
	/**
	 * Child element click timeoutID
	 * @type {number}
	 */
	childClickTimeout;

	constructor(props) {
		super(props, {
			data: {
				opened: false,
				visible: false,
			}
		}, {
			domPrefix: 'advanced-dropdown',
			domManipulationIntervalTimeout: 1,
			disableLoad: true,
		});
		
		// Refs
		this.contentRef = null;
		this.contentInnerRef = null;

		// 'Click outside' utility 
		this.handleClickOutside = this.handleClickOutside.bind(this);

		// Ref methods
		this.getContentElement = this.getContentElement.bind(this);
		this.getContentInnerElement = this.getContentInnerElement.bind(this);
		
		// Action methods
		this.toggleDropdown = this.toggleDropdown.bind(this);
		this.closeDropdown = this.closeDropdown.bind(this);
		
		// Render methods
		this.renderChildren = this.renderChildren.bind(this);
		this.renderLabel = this.renderLabel.bind(this);
		this.renderContent = this.renderContent.bind(this);
	}

	componentWillUnmount() {
		if (this.childClickTimeout > 0) clearTimeout(this.childClickTimeout);
		super.componentWillUnmount();
	}


	// 'Click outside' utility ------------------------------------------------------------------------------------------
	/**
	 * Method called by document click event handler that checks if user has clicked outside the component
	 *
	 * @param {Event} event - Click event.
	 * @private
	 */
	_hasClickedOutside(event) {
		if (
			this.wrapperRef && event.target !== this.wrapperRef && !this.wrapperRef.contains(event.target) &&
			event.target !== this.contentRef && (this.contentRef && !this.contentRef.contains(event.target))
		) {
			this.handleClickOutside(event);
		}
	}
	
	/**
	 * Method that will be called every time mouse clicks outside the component
	 * @param {Event} event - Click event.
	 */
	handleClickOutside(event) { this.setValue('opened', false).then(); }


	// DOM manipulation interval methods --------------------------------------------------------------------------------
	/**
	 * Method called on each DOM manipulation interval
	 * @param {HTMLElement|Element|null} element - Component's main DOM element or null if component's main DOM element
	 * is not set.
	 */
	domManipulations(element) {
		if (element && this.contentRef) {
			const {
				defaultPosition, parent, boundRect, contentDistance, contentMaxHeightProtection, 
				contentMaxHeightProtectionIndex, contentMaxHeight
			} = this.props;
			
			// Calculate and apply dropdown content position
			// @note Viewport overflows are handled here.
			let parentElement = null;
			if (typeof parent === 'string' && parent !== '') parentElement = document.querySelector(parent);
			else if (typeof parent !== 'string') parentElement = parent;
			const wrapperBoundRect = element.getBoundingClientRect();
			const contentBoundRect = this.contentRef.getBoundingClientRect();
			const bounds = (
				boundRect && boundRect instanceof DOMRect ?
					boundRect :
					(parentElement ? {...getViewportSize(), top: 0, right: getVw(), bottom: getVh(), left: 0} : null)
			);

			// Handle X axis position
			let left, right;
			if (defaultPosition === ADVANCED_DROPDOWN_POSITION.RIGHT) {
				const defaultRight = (wrapperBoundRect.left + contentBoundRect.width);
				const defaultLeft = wrapperBoundRect.left;
				const hasRightOverflow = (defaultRight > bounds.right);
				const rightOverflow = (defaultRight - bounds.right);
				
				if (hasRightOverflow) {
					// If content could be moved to the left to accommodate the right overflow without overflowing to the 
					// right, leave the ADVANCED_DROPDOWN_POSITION as is and just move the content a bit to the left
					if (wrapperBoundRect.left - rightOverflow >= 0) left = wrapperBoundRect.left - rightOverflow;
					// If content could not be moved to the left to accommodate the right overflow without overflowing to the 
					// right, center the content on the screen making it overflow equally on both sides
					else left = -((contentBoundRect.width - getVw()) / 2);
				} else {
					left = defaultLeft;
				}
			} else if (defaultPosition === ADVANCED_DROPDOWN_POSITION.LEFT) {
				const defaultLeft = (wrapperBoundRect.right - contentBoundRect.width);
				const hasLeftOverflow = (defaultLeft < bounds.left);
				const leftOverflow = (defaultLeft - bounds.left);
				
				if (hasLeftOverflow) {
					// If content could be moved to the right to accommodate the left overflow without overflowing to the 
					// left, leave the ADVANCED_DROPDOWN_POSITION as is and just move the content a bit to the right
					if (getVw() - (wrapperBoundRect.right - leftOverflow) >= 0) {
						right = (getVw() - (wrapperBoundRect.right - leftOverflow));
					}
					// If content could not be moved to the right to accommodate the left overflow without overflowing to the 
					// left, center the content on the screen making it overflow equally on both sides
					else right = -((contentBoundRect.width - getVw()) / 2);
				} else {
					right = (getVw() - wrapperBoundRect.right);
				}
			}

			// Handle Y axis position
			const defaultBottom = (
				wrapperBoundRect.top + wrapperBoundRect.height + contentDistance + contentBoundRect.height
			);
			const defaultTop = (wrapperBoundRect.top - contentDistance - contentBoundRect.height);
			const hasBottomOverflow = (defaultBottom > bounds.bottom);
			this.contentRef.dataset.yPos = (hasBottomOverflow ? 'top' : 'bottom');
			// Handle top overflow
			// @description If there is both bottom and top overflows, choose the side with less overflow
			if (this.contentRef.dataset.yPos === 'top' && defaultTop < bounds.top) {
				const topOverflow = (bounds.top - defaultTop);
				const bottomOverflow = (defaultBottom - bounds.bottom);
				if (topOverflow > bottomOverflow) this.contentRef.dataset.yPos = 'bottom';
			}
			// Set top position
			let top;
			if (this.contentRef.dataset.yPos === 'top') top = defaultTop;
			else top = (wrapperBoundRect.top + wrapperBoundRect.height + contentDistance);
			
			// Update position
			if (isset(left)) this.contentRef.style.left = getCssSizeString(left);
			if (isset(right)) this.contentRef.style.right = getCssSizeString(right);
			this.contentRef.style.top = getCssSizeString(top);
			
			// Fix overflow auto issue where scrollbar is render on top of the dropdown content
			this.contentRef.style.overflowY = (vertScrollbarVisible(this.contentRef) ? 'scroll' : '');
			
			// Calculate and set the safe max height of the dropdown content
			if (contentMaxHeightProtection) {
				this.contentRef.style.maxHeight = getCssSizeString((
					contentMaxHeight ? 
						Math.min(contentMaxHeight, (bounds.height * contentMaxHeightProtectionIndex)) : 
						(bounds.height * contentMaxHeightProtectionIndex)
				));
			}
		}
	}


	// Ref methods ------------------------------------------------------------------------------------------------------
	/**
	 * Get the reference of the dropdown content element
	 * @note This is meant to be used outside this component.
	 * @return {Element}
	 */
	getContentElement() { return this.contentRef; }

	/**
	 * Get the reference of the dropdown content inner element
	 * @note This is meant to be used outside this component.
	 * @return {Element}
	 */
	getContentInnerElement() { return this.contentInnerRef; }
	
	
	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Toggle dropdown
	 * 
	 * @param {MouseEvent} event - Click event.
	 * @return {Promise<Object>} Promise that resolves to entire component local state after state is updated.
	 */
	toggleDropdown(event) {
		const opened = this.getValue('opened');
		
		if (!opened) {
			executeComponentCallback(this.props.onOpen, this.contentRef);
			return this.setValue('visible', false)
				.then(() => this.invertBoolValue('opened'))
				// Wait for the content position calculation to finish before showing the content
				.then(() => new Promise(resolve => {
					setTimeout(() => resolve(), this.getOption('domManipulationIntervalTimeout') + 1)
				}))
				.then(() => this.setValue('visible', true))
				.then(() => executeComponentCallback(this.props.onOpened, this.contentRef))
				.then(() => this.state);
		} else {
			executeComponentCallback(this.props.onClose);
			return this.invertBoolValue('opened')
				.then(() => executeComponentCallback(this.props.onClosed))
				.then(() => this.state);
		}
	}

	/**
	 * Close dropdown
	 * @return {Promise<Object>}
	 */
	closeDropdown() { return this.setValue('opened', false); }
	
	
	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Return child elements (dropdown content) to render
	 */
	renderChildren() {
		const {children} = this.props;
		return React.Children.map(children, child =>
			child ?
				React.cloneElement(child, {
					className: `advanced-dropdown-item ${getString(child.props, 'className')} ${styles['item']}`,
					onClick: e => {
						// Do not trigger the 'onClick' event if child is disabled
						// @note Child is disabled if it has a 'disabled' attribute set to 'true'.
						if (e.target.dataset.disabled !== 'true') executeComponentCallback(child.props.onClick, e);
						else {
							e.preventDefault();
							return false;
						}
					},
					onMouseUp: e => {
						// Close dropdown if child element is clicked
						// @note Dropdown will not close if disabled or inactive item is clicked.
						const target = (
							e.target.classList.contains(styles['item']) ?
								e.target : 
								e.target.closest(`.${styles['item']}`)
						);
						if (
							!!target &&
							target.dataset.disabled !== 'true' &&
							target.dataset.inactive !== 'true' &&
							target.dataset.groupLabel !== 'true' &&
							target.dataset.label !== 'true'
						) {
							// Timeout is set to allow for links to work before dropdown is closed
							setTimeout(this.closeDropdown);
						}

						// Trigger child's own 'onMouseUp' event if it is defined.
						executeComponentCallback(child.props.onMouseUp);
					},
					style: {
						// Set disabled child element style
						color: getBoolean(child, 'props["data-disabled"]') ? 'var(--dropdown-text-faded)' : undefined,
					}
				})
				: null
		);
	}

	/**
	 * Render dropdown label
	 * @return {JSX.Element}
	 */
	renderLabel() {
		const {disabled, readOnly, labelClassName, label} = this.props;
		
		return React.cloneElement(label, {
			className: 
				`advanced-dropdown-label ${getString(label.props, 'className')} ${styles['label']} ${labelClassName}`,
			onClick: (
				!disabled && !readOnly ? 
					e => this.toggleDropdown(e).then(() => executeComponentCallback(label.props.onClick, e)) : 
					undefined
			),
		});
	}
	
	/**
	 * Render dropdown content
	 * @return {JSX.Element}
	 */
	renderContent() {
		const {
			styleName, contentWidth, contentMaxHeight, contentSize, contentZIndex, contentClassName, showLoading, disabled,
			readOnly, loading, 
		} = this.props;
		
		if (readOnly) return null;
		
		return (
			<div
				id={`dropdown-content-${this.getDomId()}`}
				className={
					`advanced-dropdown-content ${styles['dropdownContent']} ${styleName}-style ${contentClassName} ` +
					`${!this.getValue('visible') ? `invisible ${styles['invisible']}` : ''}`
				}
				style={{
					width: (!loading && contentWidth ? contentWidth : undefined),
					maxHeight: (!loading && contentMaxHeight ? contentMaxHeight : undefined),
					fontSize: (contentSize ? getCssSizeString(contentSize) : undefined),
					zIndex: (contentZIndex ? contentZIndex : undefined),
				}}
				ref={node => { this.contentRef = node; }}
			>
				<div
					className={`advanced-dropdown-content-inner ${styles['dropdownContentInner']}`}
					ref={node => { this.contentInnerRef = node; }}
				>
					{disabled ? null :
						(loading ? 
							(showLoading ? <div className={styles['loading']}><Spinner /></div> : null) :
							this.renderChildren()
						)
					}
				</div>
			</div>
		);
	}
	
	render() {
		const {styleName, className, disabled, readOnly, label, parent, stopPropagation} = this.props;
		let parentElement = null;
		if (typeof parent === 'string' && parent !== '') parentElement = document.querySelector(parent);
		else if (typeof parent !== 'string') parentElement = parent;
		
		return (
			<span
				id={this.getDomId()}
				className={
					`advanced-dropdown ${styles['wrapper']} ${this.getOption('domPrefix')} ` +
					`${styleName}-style ${className} ${this.getValue('opened') ? 'opened' : ''} ` +
					`${disabled ? `disabled ${styles['disabled']}` : ''} ` +
					`${readOnly ? `read-only ${styles['readOnly']}` : ''} `
				}
				onClick={e => { if (stopPropagation) e.stopPropagation(); }}
				ref={this.setWrapperRef}
			>
				{label ? this.renderLabel() : null}
				{
					this.getValue('opened') ?
						(parentElement ? ReactDOM.createPortal(this.renderContent(), parentElement) : this.renderContent())
						: null
				}
			</span>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
AdvancedDropdown.propTypes = {
	// Component style name
	// @description Component style name is a name of the style that will be used to determine the CSS used to style the
	// component.
	styleName: PropTypes.string,
	// Dropdown wrapper element id attribute
	id: PropTypes.string,
	// Dropdown wrapper element class attribute
	className: PropTypes.string,
	// Flag that determines if dropdown will be disabled
	// @description Disabled dropdown will not show its content on label click and will not trigger 'onClick' event.
	disabled: PropTypes.bool,
	// Flag that determines if dropdown is read only
	// @description Read only dropdown will not render its content and will not trigger 'onClick' event but the label 
	// will not have a disabled style like when 'disabled' props is used.
	readOnly: PropTypes.bool,
	// Default dropdown content position relative to the dropdown label
	// @note If dropdown content overflows the 'boundRect' position will be altered to appear properly on the page.
	defaultPosition: PropTypes.oneOf(ADVANCED_DROPDOWN_POSITIONS),
	// Dropdown content parent element or elements selector
	// @note If not specified, dropdown component will be the parent component. 
	parent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	// Boundary rectangle
	// @type {DOMRect} - Use 'getBoundingClientRect' to get it for any element.
	boundRect: PropTypes.object,
	// Flag that specifies if loading spinner will be shown
	showLoading: PropTypes.bool,
	// Flag that specifies if dropdown items are loading
	// @note If dropdown items are loading, no item will be rendered. Only the loading animation will be rendered if 
	// 'showLoading' prop is true.
	loading: PropTypes.bool,
	
	// Dropdown label CSS class
	// @note Dropdown label is used to open the dropdown by clicking on it
	labelClassName: PropTypes.string,
	// Dropdown trigger label component
	label: PropTypes.element,
	
	// Dropdown content CSS class
	contentClassName: PropTypes.string,
	// Dropdown content distance from the label in pixels
	contentDistance: PropTypes.number,
	// Dropdown content width in pixels
	// @note If not specified, with will be unset meaning it will be the size of the content inside.
	contentWidth: PropTypes.number,
	// Dropdown content max height in pixels
	contentMaxHeight: PropTypes.number,
	// Flag that specifies if content max height will automatically be protected from overflowing the bounds
	// @note If true, 'contentMaxHeight' prop will be used only if it is smaller than the calculated max height. Max 
	// height is calculated using the 'contentMaxHeightProtectionIndex' prop and the formula: 
	// 	bounds width * contentMaxHeightProtectionIndex
	contentMaxHeightProtection: PropTypes.bool,
	// Index to calculate the safe max with of the dropdown content
	contentMaxHeightProtectionIndex: PropTypes.number,
	// Size of the dropdown content (pixels or any other CSS unit value)
	// @note This will be used oty set the font size and all other sizes are relative to it.
	contentSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	// Dropdown content element z-index
	contentZIndex: PropTypes.number,

	// Flag that specifies if propagation will be stopped for the wrapper component click event 
	stopPropagation: PropTypes.bool,
	
	// Events
	onOpen: PropTypes.func, // Arguments: dropdown content element
	onOpened: PropTypes.func, // Arguments: dropdown content element
	onClose: PropTypes.func, // Arguments: no arguments
	onClosed: PropTypes.func, // Arguments: no arguments
};

/**
 * Define component default values for own props
 */
AdvancedDropdown.defaultProps = {
	styleName: 'default',
	id: '',
	className: '',
	disabled: false,
	readOnly: false,
	defaultPosition: ADVANCED_DROPDOWN_POSITION.RIGHT,
	parent: '',
	boundRect: null,
	showLoading: true,
	loading: false,
	labelClassName: '',
	label: null,
	contentClassName: '',
	contentDistance: 0,
	contentMaxHeightProtection: true,
	contentMaxHeightProtectionIndex: 0.5,
	contentSize: '1rem',
	contentZIndex: 10,
};

export * from "./const";
export default AdvancedDropdown;