import {getArray, getString} from 'Core/helpers/data';
import {ASCII_TAGS} from 'Tags/const';
import {CUSTOMER_CUSTOM_FIELD_TYPE} from 'Pages/apps/default/customerCustomFields/const';
import {find, map, uniq} from 'lodash';
import {tag_type_prefix_custom_field, tag_type_prefix_unsubscribe, tag_prefix, tag_suffix} from 'Config/app';
import {ltrimString, rtrimString} from 'Core/helpers/string';

/**
 * Check if a specified string contains a tag
 * @note This function will not check if the tag actual exists but only if tag-formatted value exists in the specified
 * string. Tags have a prefix and suffix, for example: ##Some tag##
 *
 * @param {string} str - String to check for tags.
 * @return {boolean}
 */
export const stringContainsTag = str => new RegExp(`${tag_prefix}(?:.*)${tag_suffix}`, 'gm').test(str);

/**
 * Check if a given personalization tag represents a customer custom field value
 * @param {string} tag - Tag to check (with prefix).
 * @return {boolean}
 */
export const isCustomFieldTag = tag => getString(tag).startsWith(`${tag_prefix}${tag_type_prefix_custom_field}`);

/**
 * Check if a given personalization tag represents an unsubscribe link value
 * @param {string} tag - Tag to check (with prefix).
 * @return {boolean}
 */
export const isUnsubscribeTag = tag => getString(tag).startsWith(`${tag_prefix}${tag_type_prefix_unsubscribe}`);

/**
 * Check if tag is guaranteed to be ASCII
 * 
 * @param {TagDataObject} tag - Tag to check.
 * @return {boolean}
 */
export const isAsciiTag = tag => {
	if (ASCII_TAGS.includes(tag.tag)) return true;

	if (isCustomFieldTag(tag.tag) &&
		[
			CUSTOMER_CUSTOM_FIELD_TYPE.INTEGER,
			CUSTOMER_CUSTOM_FIELD_TYPE.DECIMAL,
			CUSTOMER_CUSTOM_FIELD_TYPE.DATE,
			CUSTOMER_CUSTOM_FIELD_TYPE.DATE_TIME,
			CUSTOMER_CUSTOM_FIELD_TYPE.YES_NO,
		].includes(tag.tagDataType)
	) {
		return true;
	}
	
	return false;
};

/**
 * Get all tags that are guaranteed to be ASCII
 * 
 * @param {TagDataObject[]} tags - List of all supported tags.
 * @return {Tag[]}
 */
export const getAsciiTags = tags => getArray(tags).filter(tag => isAsciiTag(tag));

/**
 * Get all tags used in text content
 *
 * @param {any} content.
 * @param {TagDataObject[]} tags - All the tags supported in content.
 * @return {TagDataObject[]}
 */
export const getTagsUsedInContent = (content, tags) => {
	if (!content || typeof content !== 'string') return [];

	// Get all tags used in the content
	/** @type {TagDataObject[]} */
	let usedTags = [];
	getArray(tags).forEach(
		/** @param {TagDataObject} tag */
		tag => { if (content.includes(tag.tag) && !find(usedTags, {tag: tag.tag})) usedTags.push(tag); }
	);
	return usedTags;
};

/**
 * Get the list of unsupported tags detected in the content
 *
 * @param {any} content.
 * @param {TagDataObject[]} tags - All the tags supported in content.
 * @return {string[]}
 */
export const getUnsupportedTags = (content, tags) => {
	if (!content || typeof content !== 'string') return [];
	
	const regex = new RegExp(`(${tag_prefix}.*?${tag_suffix})`, 'gm');
	let unsupportedTags = [];
	let match;
	
	while ((match = regex.exec(content)) !== null) unsupportedTags.push(match[1]);
	unsupportedTags = uniq(unsupportedTags);
	
	return unsupportedTags.filter(tag => !getArray(map(getArray(tags), 'tag')).includes(tag));
};

/**
 * Trim tag prefix and suffix from full name (like ##Firts Name##) 
 *
 * @param {any} tag - Full tag name (like ##Firts Name##).
 * @return {string} Tag name without prefix and suffix (@see 'tag_prefix' and 'tag_suffix' application config options).
 */
export const trimTag = tag => rtrimString(ltrimString(getString(tag), tag_prefix), tag_suffix);

/**
 * Trim tag's full name (like ##Firts Name##) to get the name only (like First Name)
 * 
 * @param {any} tag - Full tag code (like ##Firts Name##).
 * @param {string} [tagTypePrefix] - Prefix used for the specific tag type (like "Custom Field:"). See 'tag_type_xxx' 
 * app options for reference.
 * @return {string}
 */
export const getTagName = (tag, tagTypePrefix) => 
	!!tagTypePrefix ? ltrimString(trimTag(tag), tagTypePrefix) : trimTag(tag);