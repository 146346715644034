/**
 * Maximal size of an SMS message in bytes
 * @note In multipart messages this will include the user data header (UDH) so actual number of characters will be 
 * reduced by the size of the UDH.
 * @type {number}
 */
export const SMS_MAX_SIZE_BYTES = 140;
/**
 * SMS user data header size in bytes
 * @note Multipart SMS messages have a user data header (UDH) containing segmentation information.
 * @type {number}
 */
export const SMS_UDH_SIZE_BYTES = 6;

/** @typedef {string} SmsEncoding */
export const SMS_ENCODING_GSM7 = 'GSM-7';
export const SMS_ENCODING_UCS2_UTF16 = 'UCS-2/UTF-16';
/** @enum {SmsEncoding} */
export const SMS_ENCODING = {
	GSM7: SMS_ENCODING_GSM7,
	UCS2_UTF16: SMS_ENCODING_UCS2_UTF16,
};
/** @type {SmsEncoding[]} */
export const SMS_ENCODINGS = [
	SMS_ENCODING_GSM7,
	SMS_ENCODING_UCS2_UTF16,
];



// @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Lexical_grammar
export const GSM_7_SP = '\u0020'; // \s
export const GSM_7_ESC = '\u00A0';
export const GSM_7_LF = '\u000A'; // \n
export const GSM_7_CR = '\u000D'; // \r
export const GSM_7_FF = '\u000C'; // \f

export const GSM_7_CHARSET_BASIC = [
	'@', 'Δ', GSM_7_SP, '0', '¡', 'P', '¿', 'p',
	'£', '_', '!', '1', 'A', 'Q', 'a', 'q',
	'$', 'Φ', '"', '2', 'B', 'R', 'b', 'r',
	'¥', 'Γ', '#', '3', 'C', 'S', 'c', 's',
	'è', 'Λ', '¤', '4', 'D', 'T', 'd', 't',
	'é', 'Ω', '%', '5', 'E', 'U', 'e', 'u',
	'ù', 'Π', '&', '6', 'F', 'V', 'f', 'v',
	'ì', 'Ψ', `'`, '7', 'G', 'W', 'g', 'w',
	'ò', 'Σ', '(', '8', 'H', 'X', 'h', 'x',
	'Ç', 'Θ', ')', '9', 'I', 'Y', 'i', 'y',
	GSM_7_LF, 'Ξ', '*', ':', 'J', 'Z', 'j', 'z',
	'Ø', GSM_7_ESC, '+', ';', 'K', 'Ä', 'k', 'ä',
	'ø', 'Æ', ',', '<', 'L', 'Ö', 'l', 'ö',
	GSM_7_CR, 'æ', '-', '=', 'M', 'Ñ', 'm', 'ñ',
	'Å', 'ß', '.', '>', 'N', 'Ü', 'n', 'ü',
	'å', 'É', '/', '?', 'O', '§', 'o', 'à'
];
export const HAS_GSM_7_CHARSET_BASIC_REGEXP = /[A-Za-z0-9@£$¥èéùìòÇØøÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ!"#¤%&'()*+,\-./¡:;<=>?ÄÖÑÜ§¿äöñüà\s\n\r\u00A0]/;

export const GSM_7_CHARSET_EXTENSION = ['|', '^', '€', '{', '}', '[', ']', '~', '\\', GSM_7_FF];
export const HAS_GSM_7_CHARSET_EXTENSION_REGEXP = /[|^€{}[\]~\\\f]/;
export const ALL_GSM_7_CHARSET_EXTENSION_REGEXP = /[|^€{}[\]~\\\f]/g;

export const GSM_7_CHARSET = [...GSM_7_CHARSET_BASIC, ...GSM_7_CHARSET_EXTENSION];
export const HAS_GSM_7_CHARSET_REGEXP = /[A-Za-z0-9@£$¥èéùìòÇØøÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ!"#¤%&'()*+,\-./¡:;<=>?ÄÖÑÜ§¿äöñüà\s\n\r\u00A0|^€{}[\]~\\\f]/;
export const HAS_NON_GSM_7_CHARSET_REGEXP = /[^A-Za-z0-9@£$¥èéùìòÇØøÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ!"#¤%&'()*+,\-./¡:;<=>?ÄÖÑÜ§¿äöñüà\s\n\r\u00A0|^€{}[\]~\\\f]/;