import React from "react";
import PageDataComponent from "Core/components/PageDataComponent";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {getPageActions} from "Core/helpers/redux";
import {selectors} from "Core/store/reducers";
import * as appConfig from "../config";
import * as pageConfig from "./config";
import * as actions from "./actions";
import {getMenuSidebarShrankFromStorage} from "Layout/elements/MainSidebar/helpers";
import {reducerStoreKey} from "./reducer";
import {areAllObjectPropsEmpty, getArray, getNumber, isset, trimArray} from "Core/helpers/data";
import * as filterDataMap from "./dataMap/filter";
import {scrollToSelector} from "Core/helpers/dom";
import {getPopupInstance} from "Core/helpers/popup";
import ItemPopup from "./popups/ItemPopup";
import {Tooltip} from "react-tippy";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import {icon_font_create_symbol, icon_font_delete_symbol} from "Config/app";
import ConfirmDialog from "Core/components/dialogs/ConfirmDialog";
import Label from "Core/components/display/Label";
import DataTable, {DATA_TABLE_CELL_TYPE} from "Core/components/advanced/DataTable";
import {PAGINATION_TYPE} from "Core/components/action/Pagination";
import {isSuccessful} from "Core/helpers/io";
import {AsyncMountError} from "Core/errors";
import {
	DataTableCellAnyTypeOptionsDataObject,
	DataTableCellTextTypeOptionsDataObject
} from 'Core/components/advanced/DataTable/DataTableCell/dataObjects';
import Acl from 'Acl/index';

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	isMobileBreakpoint: selectors.breakpoint.isMobileBreakpoint(state),
	mainSidebarShrank: getMenuSidebarShrankFromStorage(selectors.mainSidebar.shrank(state)),
	mainList: selectors[reducerStoreKey].getCustomerCustomFieldsList(state),
	mainListPagination: selectors[reducerStoreKey].getCustomerCustomFieldsListPagination(state),
	mainListSort: selectors[reducerStoreKey].getCustomerCustomFieldsListSort(state),
	mainListFilter: selectors[reducerStoreKey].getCustomerCustomFieldsListFilter(state),
	singleProject: selectors.projectSelector.getSingleProject(state),
});

class CustomerCustomFieldsPage extends PageDataComponent {
	constructor(props) {
		super(props, {
			data: {
				/**
				 * Currently selected search filter
				 */
				filter: {},
				/**
				 * Flag showing if filter is loading
				 */
				filterLoading: false,

				/**
				 * Flag showing if page is loading data
				 * @type {boolean}
				 */
				loading: false,
			},

			/**
			 * Flag that specifies if main data table height will be limited to the available space
			 */
			limitToAvailableSpace: true
		}, {
			domPrefix: 'customer-custom-fields-page',
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
			checkLogin: false,
			disableLoad: true,
		}, 'page_title');

		// Refs
		this.mainListFilterRef = null;

		// Data methods
		this.reloadMainList = this.reloadMainList.bind(this);
		this.loadMainListPage = this.loadMainListPage.bind(this);
		this.sortMainList = this.sortMainList.bind(this);
		this.filterMainList = this.filterMainList.bind(this);
		this.removeMainListFilter = this.removeMainListFilter.bind(this);
		this.isMainListFilterEmpty = this.isMainListFilterEmpty.bind(this);

		// Popup methods
		this.openMainListItemPopup = this.openMainListItemPopup.bind(this);
		this.closeMainListItemPopup = this.closeMainListItemPopup.bind(this);
		this.handleMainListItemPopupClose = this.handleMainListItemPopupClose.bind(this);

		// Action methods
		this.deleteMainListItem = this.deleteMainListItem.bind(this);

		// Render methods
		this.renderActions = this.renderActions.bind(this);
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called on component mount and should be used to load any data required by the page
	 * @return {Promise<any>}
	 * @throws {AsyncMountError}
	 */
	loadPageData() {
		const {mainList, loadCustomerCustomFieldsListAction} = this.props;

		// If main list was already loaded (user already opened the page before)
		if (isset(mainList)) {
			// Open filter if it is not empty
			if (!this.isMainListFilterEmpty() && this.mainListFilterRef) this.mainListFilterRef.open();
			// Reload main list with currently applied filter, sort and pagination
			return this.reloadMainList()
				.then(res => { if (!isset(res)) throw new AsyncMountError(); });
		}
		// Load main list if it is not already loaded
		else {
			return this.setValue('loading', true)
				.then(() => this.executeAbortableActionMount(loadCustomerCustomFieldsListAction))
				.then(() => this.setValue('loading', false));
		}
	}

	/**
	 * Reload main list using current options (page, sort, ...)
	 * @return {Promise<*>}
	 */
	reloadMainList() {
		const {loadCustomerCustomFieldsListAction, mainListPagination, mainListSort, mainListFilter} = this.props;
		const {pageNo, perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(loadCustomerCustomFieldsListAction, oFilter, pageNo, perPage, sortBy, sortDir)
			.then(res => {
				this.mainListFilterRef?.reload();
				return res;
			});
	}

	/**
	 * Reload main list using current options (page, sort, ...) if any
	 * @param {number} [pageNo=1] - Page number to load (starts with 1).
	 * @return {Promise<*>}
	 */
	loadMainListPage(pageNo = 1) {
		const {loadCustomerCustomFieldsListAction, mainListPagination, mainListSort, mainListFilter} = this.props;
		const {perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(loadCustomerCustomFieldsListAction, oFilter, pageNo, perPage, sortBy, sortDir);
	}

	/**
	 * Sort main list
	 * @param {string} sortBy - Name of the sort column.
	 * @param {string} sortDir - Direction of the sort.
	 * @return {Promise<*>}
	 */
	sortMainList(sortBy, sortDir) {
		const {loadCustomerCustomFieldsListAction, mainListPagination, mainListFilter} = this.props;
		const {pageNo, perPage} = mainListPagination;
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(loadCustomerCustomFieldsListAction, oFilter, pageNo, perPage, sortBy, sortDir);
	}

	/**
	 * Filter main list
	 * @param {Object} filter - Filter object where keys are filter field names and values are filter values.
	 * @return {Promise<*>}
	 */
	filterMainList(filter) {
		const {
			loadCustomerCustomFieldsListAction, setCustomerCustomFieldsFilterAction, mainListPagination, mainListSort,
		} = this.props;
		const {perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(filter);
		
		// Set filter so that the change will be detected after IO
		setCustomerCustomFieldsFilterAction(filter);

		return this.setValue('filterLoading', true)
			.then(() => this.executeAbortableAction(loadCustomerCustomFieldsListAction, oFilter, 1, perPage, sortBy, sortDir))
			.then(() => this.setValue('filterLoading', false))
			.then(() => {
				if (areAllObjectPropsEmpty(oFilter, true, false)) {
					if (this.mainListFilterRef) {
						this.mainListFilterRef.close()
							.then(() => this.setState({limitToAvailableSpace: true}));
					}
				} else {
					scrollToSelector('#main-page-table', false, 80);
				}
			});
	}

	/**
	 * Remove main list filter
	 * @return {Promise<*>}
	 */
	removeMainListFilter() {
		return this.filterMainList(null);
	}

	/**
	 * Check if main list filter is applied
	 * @return {Boolean}
	 */
	isMainListFilterEmpty() {
		return areAllObjectPropsEmpty(this.getProp('mainListFilter'), true, false);
	}


	// Router methods ---------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called if current URL matches the 'create' sub-url of the page
	 * @note Create sub-url uses '/new' router path relative to the router path of the page (see 'options.routerPath').
	 *
	 * @param {Object} [prevLocation] - Previous router location.
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on
	 * 'create' sub-url if such component exists.
	 */
	handleCreateUrl(prevLocation) {
		const canEdit = Acl.checkPermission(Acl, ['CUSTOMER_CUSTOM_FIELD_WRITE']);
			
		if (canEdit) {
			// Open main list item popup if it is not already opened
			// @note This is done to avoid opening multiple dialogs when switching from item edit to new item URL. Dynamic URL
			// component data should be changes using the standard 'updateDynamics' method.
			if (!this.urlComponentGUIID) {
				return this.openMainListItemPopup(true);
			}
			// If URL component is opened, update its dynamic items (tabs, action buttons, ...)
			else {
				const mainListItemPopup = getPopupInstance(this.urlComponentGUIID);
				return mainListItemPopup.updateDynamics({isNew: true}).then(() => this.urlComponentGUIID);
			}
		} else {
			this.redirectToBase();
		}
	}

	/**
	 * Method that will be called if current URL matches the 'item' sub-url of the page
	 * @note Item sub-url uses '/item' router path and 'id' as router path param ('/item/:id') on top of to the router
	 * path of the page (see 'options.routerPath').
	 *
	 * @param {string|number} id - Item ID.
	 * @param {Object} [prevLocation] - Previous router location.
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on
	 * 'item' sub-url if such component exists.
	 */
	handleItemUrl(id, prevLocation) {
		/** [NO-API] Redirect to base page URL because editing an existing list item is not needed at this time (no API support) */
		this.redirectToBase();
		
		/** [NO-API] Commented out because editing an existing list item is not needed at this time (no API support) */
		// const {loadCustomerCustomFieldsItemAction} = this.props;
		//
		// // @note This is done to avoid opening multiple dialogs when switching from new item to edit item URL. Dynamic URL
		// // component data should be changes using the standard 'updateDynamics' method.
		// if (!this.urlComponentGUIID) {
		// 	this.urlComponentGUIID = this.openMainListItemPopup();
		// }
		// // If URL component is opened, update its dynamic items (tabs, action buttons, ...)
		// else {
		// 	const mainListItemPopup = getPopupInstance(this.urlComponentGUIID);
		// 	mainListItemPopup.updateDynamics({isNew: false}).then();
		// }
		//
		// // Try to load main list item
		// return new Promise(resolve =>
		// 	// Timeout is added to allow for the popup open animation to finish
		// 	setTimeout(() => resolve(
		// 		this.executeAbortableAction(loadCustomerCustomFieldsItemAction, id)
		// 			.then(mainListItem => {
		// 				// If main list item is successfully loaded
		// 				if (mainListItem) {
		// 					return this.urlComponentGUIID;
		// 				}
		// 				// If main list item could not be loaded (usually if item with ID from URL does not exist)
		// 				else {
		// 					// Close main list item popup if it is opened
		// 					if (this.urlComponentGUIID) this.closeUrlComponent();
		// 					// Redirect to page base url (removes main list item ID from URL if it exists)
		// 					this.redirectToBase();
		// 					return '';
		// 				}
		// 			})
		// 	))
		// );
	}

	/**
	 * Method that will be called if current URL matches the base URL of the page
	 *
	 * @param {Object} [prevLocation] - Previous router location.
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on its
	 * base URL if such component exists.
	 */
	handleBaseUrl(prevLocation) {
		this.closeMainListItemPopup();
		return '';
	}

	/**
	 * Method that will be called when page component unmounts and should handle closing of any page url or sub-url
	 * component if it exists.
	 */
	closeUrlComponent() {
		this.closeMainListItemPopup();
	}


	// Popup methods ----------------------------------------------------------------------------------------------------
	/**
	 * Open main list item popup
	 * @param {boolean} [isNew=false] - Flag that specifies if new main list item popup should be opened.
	 */
	openMainListItemPopup(isNew = false) {
		const {openPopupAction} = this.props;
		return openPopupAction(ItemPopup, {
			isNew,
			onClose: this.handleMainListItemPopupClose,
			deleteItemAction: this.deleteMainListItem,
			redirectToItem: this.redirectToItem,
			redirectToBase: this.redirectToBase,
		});
	}

	/**
	 * Close main list item popup
	 */
	closeMainListItemPopup() {
		const {closePopupAction, clearCustomerCustomFieldsItemAction} = this.props;

		// Close item popup
		const urlComponentGUIID = this.getUrlComponentGUIID();
		if (urlComponentGUIID) {
			closePopupAction(urlComponentGUIID);
			this.clearUrlComponentGUIID();
		}

		// Clear popup Redux data
		clearCustomerCustomFieldsItemAction();
	}

	/**
	 * Handle main list item popup 'onClose' event
	 * @return {void}
	 */
	handleMainListItemPopupClose() {
		this.clearUrlComponentGUIID();
		this.redirectToBase();
		// Timeout is added to allow for the popup close animation to finish
		setTimeout(this.reloadMainList);
	}


	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Delete main list item
	 * 
	 * @param {CustomerCustomFieldsListItemDataObject} item - Main list popup item.
	 * @return {Promise<void>}
	 */
	deleteMainListItem(item) {
		const {
			openDialogAction, closeDialogAction, deleteCustomerCustomFieldsItemAction, addSuccessMessageAction
		} = this.props;
		
		return new Promise(resolve => {
			const dialogGUIID = openDialogAction('', ConfirmDialog, {
				message: this.t('confirm_delete', '', '', {name: item.name}),
				supportHtml: true,
				onYes: () => {
					this.executeAbortableAction(deleteCustomerCustomFieldsItemAction, item.id)
						.then(response => {
							if (isSuccessful(response)) {
								addSuccessMessageAction(this.tt('delete_success_msg', 'ItemPopup'));
								// Reload main list data
								// @note This is done asynchronously on purpose because there is no reason to wait for this to 
								// finish before continuing.
								this.reloadMainList()
									// Go to the previous page if there are no table rows after one has been deleted
									.then(() => {
										const mainList = getArray(this.props, 'mainList');
										const pageNo = getNumber(this.props, 'mainListPagination.pageNo', 1);
										if (mainList.length === 0 && pageNo > 1) return this.loadMainListPage(pageNo - 1);
									});
							}
							return Promise.resolve(response);
						})
						.then(() => closeDialogAction(dialogGUIID))
						.finally(() => resolve('yes'));
				},
				onNo: () => {
					closeDialogAction(dialogGUIID);
					resolve('no');
				}
			}, {
				id: 'item-delete-dialog',
				closeOnEscape: true,
				closeOnClickOutside: true,
				hideCloseBtn: true,
				maxWidth: 500
			});
			this.setOption(
				'dialogsToCloseOnUnmount', 
				trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
			);
		});
	}
	

	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render data table actions cell
	 * @param {CustomerCustomFieldsListItemDataObject} row - Data table row.
	 * @return {JSX.Element}
	 */
	renderActions(row) {
		return (
			<div className="actions standard-actions-row">
				<Tooltip
					tag="div"
					title={this.t('delete_tooltip')}
					size="small"
					position="top-center"
					arrow={true}
					interactive={false}
				>
					<Button
						className="action-btn no-border-radius"
						displayStyle={BUTTON_STYLE.ACTION}
						displayType={BUTTON_DISPLAY_TYPE.NONE}
						icon={icon_font_delete_symbol}
						onClick={() => this.deleteMainListItem(row)}
					/>
				</Tooltip>

				{/* [NO-API] Commented out because editing an existing list item is not needed at this time (no API support) */}
				{/*<Tooltip*/}
				{/*	tag="div"*/}
				{/*	title={this.t('edit_tooltip')}*/}
				{/*	size="small"*/}
				{/*	position="top-center"*/}
				{/*	arrow={true}*/}
				{/*	interactive={false}*/}
				{/*>*/}
				{/*	<Button*/}
				{/*		className="action-btn no-border-radius"*/}
				{/*		displayStyle={BUTTON_STYLE.ACTION}*/}
				{/*		displayType={BUTTON_DISPLAY_TYPE.NONE}*/}
				{/*		icon={icon_font_edit_symbol}*/}
				{/*		onClick={() => this.redirectToItem(row.id)}*/}
				{/*	/>*/}
				{/*</Tooltip>*/}
			</div>
		);
	}

	/**
	 * Render page title
	 * @description This method specifies how page title will be rendered if page title should be rendered. It does not
	 * determine if page title should be rendered.
	 * @return {JSX.Element}
	 */
	renderPageTitle() {
		const {title} = this.state;
		const canEdit = Acl.checkPermission(Acl, ['CUSTOMER_CUSTOM_FIELD_WRITE']);

		return (
			<h1 className={`page-title with-actions ${!canEdit ? 'reload-only' : ''}`}>
				<div className="content">{title ? this.translate(title, this.titlePathPrefix) : ''}</div>
				<div className="actions">
					<div className="action-button">
						<Tooltip
							tag="div"
							title={this.t('Reload data', 'general')}
							size="small"
							position="top-center"
							arrow={true}
							interactive={false}
						>
							<Button
								big={true}
								icon="refresh"
								displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
								displayStyle={BUTTON_STYLE.SUBTLE}
								onClick={this.reloadMainList}
							/>
						</Tooltip>
					</div>

					{canEdit ?
						<div className="action-button main-button">
							<Link to={this.getCreateRedirectTo()} className="button action solid big">
								<Label icon={icon_font_create_symbol} content={this.t('create_new')}/>
							</Link>
						</div>
						: null
					}
				</div>
			</h1>
		);
	}

	render() {
		const {
			mainList, mainListPagination, mainListSort, mainSidebarShrank, toggleMainSidebarSizeAction, singleProject, 
			// mainListFilter
		} = this.props;
		const {limitToAvailableSpace} = this.state;
		const canEdit = Acl.checkPermission(Acl, ['CUSTOMER_CUSTOM_FIELD_WRITE']);
		
		return this.renderLayout((
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')}`}>
				{
					this.hasTranslation('page_short_description') && this.t('page_short_description') ?
						<div className="simple-page-description">
							<Label content={this.t('page_short_description')} supportHtml={true} />
						</div>
						: null
				}

				{/* [NO-API] Commented out because API does not support it at this time */}
				{/*<SimpleStaticSearch*/}
				{/*	styleName="compact"*/}
				{/*	className="main-search rows-1"*/}
				{/*	defaultCollapse={true}*/}
				{/*	layout={SIMPLE_STATIC_SEARCH_LAYOUT.STACKED}*/}
				{/*	buttonProps={{*/}
				{/*		displayStyle: BUTTON_STYLE.DEFAULT*/}
				{/*	}}*/}
				{/*	options={[*/}
				{/*		new SimpleStaticSearchOptionObject(*/}
				{/*			'name',*/}
				{/*			this.tt('nameField', 'ItemPopup.MainTab')*/}
				{/*		),*/}
				{/*	]}*/}
				{/*	value={mainListFilter}*/}
				{/*	title={(<Label icon="search" content={this.t('Search', 'general')} />)}*/}
				{/*	applied={!this.isMainListFilterEmpty()}*/}
				{/*	enableToolbar={true}*/}
				{/*	enableResetButton={false}*/}
				{/*	onChange={this.filterMainList}*/}
				{/*	onRemove={this.removeMainListFilter}*/}
				{/*	onToggle={visible => this.setState({limitToAvailableSpace: visible})}*/}
				{/*	ref={node => { this.mainListFilterRef = node; }}*/}
				{/*/>*/}

				<DataTable
					id="main-page-table"
					className={`standard ${canEdit ? 'sticky-last-column' : ''}`}
					responsive={true}
					responsiveBreakpointName="bp-s"
					limitToAvailableSpace={limitToAvailableSpace && !this.getProp('isMobileBreakpoint')}
					highlightOnHover={true}
					columns={[
						{
							name: 'dataType',
							label: this.tt('dataTypeField', 'ItemPopup.MainTab'),
							dataTypeOptions: new DataTableCellTextTypeOptionsDataObject({
								translatePath: this.getTranslationPath('dataType'),
								whiteSpace: 'nowrap',
							}),
							width: 1,
						},
						{
							name: 'name',
							label: this.tt('nameField', 'ItemPopup.MainTab'),
						},
						{
							name: 'description',
							label: this.tt('descriptionField', 'ItemPopup.MainTab'),
							defaultValue: '—',
							emptyAsDefault: true,
						},
						
						{
							dataType: DATA_TABLE_CELL_TYPE.ANY,
							dataTypeOptions: new DataTableCellAnyTypeOptionsDataObject({
								standardWrapper: false,
								content: this.renderActions,
							}),
							stopPropagation: true,
							width: 1,
							hide: !canEdit,
						}
					]}
					// [NO-API] Commented out because editing an existing list item is not needed at this time (no API support)
					// onRowClick={data => this.redirectToItem(data.id)}
					data={mainList}
					paginationType={PAGINATION_TYPE.STATIC}
					onSortByColumn={this.sortMainList}
					onPaginationClick={this.loadMainListPage}
					{...mainListPagination}
					{...mainListSort}
				/>
			</div>
		), (!singleProject ? 'no-project-selector' : undefined), undefined, {
			app: appConfig,
			mainSidebarShrank,
			toggleMainSidebarSizeAction,
		});
	}
}

export * from "./config";
export default connect(mapStateToProps, getPageActions(actions))(CustomerCustomFieldsPage);