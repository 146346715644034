import {getStringForDisplay} from 'Core/helpers/data';
import {get} from 'lodash';
import {getTagsUsedInContent} from 'Tags/helpers';
import {TAG_LENGTH_PRECISION} from 'Tags/const';

/**
 * Returns a massage definition popup tab title 
 * @param {CampaignItemDataObject} campaign - Campaign data object.
 * @return {`[${string}] ${string}`}
 */
export const getTabTitle = campaign => {
	return `[${getStringForDisplay(get(campaign, 'id'))}] ${getStringForDisplay(get(campaign, 'name'))}`;
}

/**
 * Check if message content has dynamic tags
 * @note Dynamic tags are tags that do not have precise length.
 * 
 * @param {any} [content] - Content to check;
 * @param {TagDataObject[]} tags - All the tags supported in the message content.
 * @return {boolean}
 */
export const isMessageContentDynamic = (content, tags) => {
	if (!content || typeof content !== 'string') return false;
	
	const usedTags = getTagsUsedInContent(content, tags);
	return (usedTags.length === 0 ? false : usedTags.some(tag => tag.lengthPrecision === TAG_LENGTH_PRECISION.AVERAGE));
};
