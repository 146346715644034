import PropTypes from "prop-types";
import PopupComponent, {
	PopupActionDataObject,
	PopupTabDataObject
} from 'Core/components/PopupComponent';
import {connect} from "react-redux";
import * as pageConfig from "../../config";
import {getPageActions} from "Core/helpers/redux";
import * as actions from "../../actions";
import {cloneDeep, orderBy, get, isEqual} from "lodash";
import {
	icon_font_close_symbol, 
	icon_font_create_symbol, 
	icon_font_save_symbol,
} from "Config/app";
import {BUTTON_STYLE} from 'Core/components/display/Button';
import {getBool, getString, isset, trimArray} from 'Core/helpers/data';
import {getCurrentProjectIdFromSession} from 'Helpers/project';
import ConfirmDialog from 'Core/components/dialogs/ConfirmDialog';
import {selectors} from 'Core/store/reducers';
import {reducerStoreKey} from '../../reducer';
import Acl from 'Acl/projects';

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	cappingItemName: getString(selectors[reducerStoreKey].getCappingItem(state), 'name'),
	cappingItemActive: getBool(selectors[reducerStoreKey].getCappingItem(state), 'active'),
});

class ItemPopup extends PopupComponent {
	/**
	 * IMPORTANT! Must be defined in components that extend this abstract component like this:
	 * dirname = __dirname;
	 *
	 * @note This is done in order for automatic tab component loading to work properly.
	 */
	dirname = __dirname;

	constructor(props) {
		super(props, {
			translationPath: `${pageConfig.translationPath}.ItemPopup`,
			domPrefix: 'item-popup',
			hideSingleTab: true,
		});

		this.initialState = {
			/**
			 * List of all popup tabs
			 * @type {PopupTabDataObject[]}
			 */
			tabs: [],

			/**
			 * List of all popup actions
			 * @type {PopupActionDataObject[]}
			 */
			actions: [],

			/**
			 * ID of the currently opened tab
			 * @type {string}
			 */
			currentTabId: '',
		};

		this.state = cloneDeep(this.initialState);

		// Action methods
		this.save = this.save.bind(this);
		this.saveAndClose = this.saveAndClose.bind(this);
		this.delete = this.delete.bind(this);
		this.deactivate = this.deactivate.bind(this);
		this.activate = this.activate.bind(this);
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		const {isNew} = this.props;
		
		// Update dynamic buttons when item active status changes
		if (!isNew && this.props.cappingItemActive !== prevProps.cappingItemActive) {
			const isNew = getBool(this.props, 'isNew');
			const isActive = getBool(this.props, 'cappingItemActive');
			this.dynamicActionButtons({isNew, isActive}).then();
		}
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// Tab methods ------------------------------------------------------------------------------------------------------
	/**
	 * Update dynamic action buttons that depend on current state and props
	 * @param {boolean} [isNew] - Flag that specifies if this popup is for a new item.
	 * @param {boolean} [isActive] - Flag that specifies if limit is active.
	 * @return {Promise<void>}
	 */
	async dynamicActionButtons({isNew, isActive}) {
		const projectId  = getCurrentProjectIdFromSession();
		const canEdit = (!!projectId ? Acl.checkPermission(Acl, projectId, ['FREQUENCY_CAPPING_PLAN_WRITE']) : false);
		
		// Define all actions
		const actionDefinitions = {
			'create': new PopupActionDataObject({
				id: 'create',
				action: this.save,
				buttonProps: {
					label: this.getTranslationPath('create_action'),
					icon: icon_font_create_symbol,
					displayStyle: BUTTON_STYLE.ACTION
				},
				ordinal: 3
			}),
			'update': new PopupActionDataObject({
				id: 'update',
				action: this.save,
				buttonProps: {
					label: this.getTranslationPath('update_action'),
					icon: icon_font_save_symbol,
					displayStyle: BUTTON_STYLE.ACTION
				},
				ordinal: 3
			}),
			'update_and_close': new PopupActionDataObject({
				id: 'update_and_close',
				action: this.saveAndClose,
				buttonProps: {
					label: this.getTranslationPath('update_and_close_action'),
					icon: icon_font_save_symbol,
					displayStyle: BUTTON_STYLE.ACTION
				},
				ordinal: 2,
			}),
			// 'delete': new PopupActionDataObject({
			// 	id: 'delete',
			// 	action: this.delete,
			// 	buttonProps: {
			// 		label: 'general.Delete',
			// 		icon: icon_font_delete_symbol,
			// 		displayStyle: BUTTON_STYLE.ERROR
			// 	},
			// 	ordinal: 1
			// }),
			'change_status': new PopupActionDataObject({
				id: 'change_status',
				action: isActive ? this.deactivate : this.activate,
				buttonProps: {
					icon: isActive ? 'ban' : 'check',
					label: this.getTranslationPath(`${isActive ? 'deactivate' : 'activate'}_action`),
					displayStyle: BUTTON_STYLE.ACTION,
				},
				ordinal: 1,
			})
		};

		// Define actions to remove and updated based on dynamic values
		let actionsToRemove;
		let actionsToUpdate;
		if (isNew) {
			actionsToRemove = (
				canEdit ?
					[/*'delete',*/ 'update', 'update_and_close', 'change_status'] :
					['create', /*'delete',*/ 'update', 'update_and_close', 'change_status']
			)
			actionsToUpdate = (canEdit ? ['create'] : []);
		} else {
			actionsToRemove = (
				canEdit ?
					['create'] :
					['create', /*'delete',*/ 'update', 'update_and_close', 'change_status']
			);
			actionsToUpdate = (
				canEdit ?
					[/*'delete',*/ 'update', 'update_and_close', 'change_status'] :
					[]
			);
		}
		
		// Remove and update actions
		// @note You usually do not have to change anything here.
		await Promise.allSettled([
			this.removeActions(actionsToRemove),
			...actionsToUpdate.map(id => {
				const prevAction = this.getAction(id);
				const action = actionDefinitions[id];
				if (!isEqual(prevAction, action)) {
					if (this.actionExists(id)) return this.updateAction(id, action);
					else return this.addAction(action);
				}
				return Promise.resolve();
			})
		]);
	}

	/**
	 * Initialize popup by specifying initial tabs, actions and current tab
	 * @note If current tab is not set it will default to the first visible and valid tab. Valid tab is tab that has
	 * 'component' property specified (manually or automatically loaded).
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	async init() {
		const isNew = getBool(this.props, 'isNew');
		const isActive = getBool(this.props, 'cappingItemActive');

		// Add static actions that don't depend on current state or props
		let actions = [
			new PopupActionDataObject({
				id: 'close',
				action: this.close,
				buttonProps: {
					label: 'general.Close',
					icon: icon_font_close_symbol
				},
				ordinal: 0
			}),
		];
		await this.setActions(actions);
		// Add dynamic actions that depend on current state or props
		await this.dynamicActionButtons({isNew, isActive});

		// Add tabs
		await this.setTabs([
			new PopupTabDataObject({
				id: 'MainTab',
			}),
		]).then(this.importTabComponents);
		// Update dynamic tabs that depend on current state or props
		await this.dynamicTabs({isNew});

		return Promise.resolve(this.state);
	}

	/**
	 * Try to automatically load tab components from standard location for tabs that don't have components defined
	 * @note To automatically load tab components the need to be located in a 'tabs' subdirectory either as a component
	 * file (like ./tabs/InfoTab.js) or subdirectory with index file (./tabs/InfoTab/index.js) where directory name or
	 * filename must be the tab ID.
	 *
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	importTabComponents() {
		const tabs = orderBy(this.getSortedTabs(), ['preloadPriority'], ['desc']);
		return Promise.all(tabs.map(tab => {
			if (!isset(tab.component)) return this.handleTabComponentImport(tab, import(`./tabs/${tab.id}`));
			else return Promise.resolve(this.state);
		}));
	}


	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Save item
	 * @note This method will handle both updating and creating a new item.
	 *
	 * @param {Object} allTabsData - Internal tab data object where keys are tab IDs and values are internal tabs data.
	 * @param {MouseEvent} event - Mouse click event for clicked action button DOM element.
	 * @param {'create'|'update'} actionId - ID of the clicked action.
	 * @return {Promise<*>}
	 */
	save(allTabsData, event, actionId) {
		const {
			redirectToItem, createCappingItemAction, updateCappingItemAction, loadCappingListAction, 
			addSuccessMessageAction, openDialogAction, closeDialogAction
		} = this.props;

		return this.getTabRef('MainTab').validateTab()
			.then(valid => {
				if (valid) {
					/** @type {CappingItemDataObject} */
					const item = get(allTabsData, 'MainTab');

					// Create item
					if (actionId === 'create') {
						return new Promise(resolve => {
							const dialogGUIID = openDialogAction('', ConfirmDialog, {
								message: this.t('confirm_create'),
								supportHtml: true,
								onYes: () => {
									this.executeAbortableAction(createCappingItemAction, item)
										.then(createdItem => {
											if (createdItem?.id) {
												const projectId  = getCurrentProjectIdFromSession();

												// Close confirm dialog
												closeDialogAction(dialogGUIID);

												// Redirect to item URL if item was created successfully and show success message
												redirectToItem(createdItem.id);
												addSuccessMessageAction(this.t('create_success_msg'));

												// Reload item list (data table) without rendering the loading overlay
												// @note This is done asynchronously on purpose because there is no reason to wait 
												// for this to finish before continuing.
												this.executeAbortableAction(loadCappingListAction,
													projectId, undefined, undefined, undefined, undefined, undefined, false
												).then();
											}
											closeDialogAction(dialogGUIID);
											resolve(createdItem);
										});
								},
								onNo: () => {
									closeDialogAction(dialogGUIID);
									resolve();
								}
							}, {
								id: 'item-confirm-create-dialog',
								closeOnEscape: true,
								closeOnClickOutside: true,
								hideCloseBtn: true,
								maxWidth: 670
							});
							this.setOption(
								'dialogsToCloseOnUnmount',
								trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
							);
						});
					}
					// Update item
					else if (actionId === 'update') {
						return this.executeAbortableAction(
							updateCappingItemAction,
							getString(item, 'id', '', true),
							item
						)
							.then(response => {
								if (response) addSuccessMessageAction(this.t('update_success_msg'));
								return response;
							});
					} else {
						console.error(`Invalid capping item popup save method: ${actionId}`);
					}
				}
				return Promise.resolve();
			});
	}

	/**
	 * Save item and close the popup if successful
	 *
	 * @param {Object} allTabsData - Internal tab data object where keys are tab IDs and values are internal tabs data.
	 * @param {MouseEvent} event - Mouse click event for clicked action button DOM element.
	 * @return {Promise<*>}
	 */
	saveAndClose(allTabsData, event) {
		return this.save(allTabsData, event, 'update')
			.then(response => {
				if (isset(response)) this.close();
				return response;
			});
	}

	/**
	 * Delete item
	 *
	 * @param {Object} allTabsData - Internal tab data object where keys are tab IDs and values are internal tabs data.
	 * @param {MouseEvent} event - Mouse click event for clicked action button DOM element.
	 * @param {'create'|'update'} actionId - ID of the clicked action.
	 * @return {Promise<*>}
	 */
	delete(allTabsData, event, actionId) {
		const {deleteItemAction, redirectToBase} = this.props;
		const item = get(allTabsData, 'MainTab');
		if (item && item.id) return deleteItemAction(item).then(event => { if (event === 'yes') redirectToBase(); });
		return Promise.resolve();
	}

	/**
	 * Activate item
	 * @note This method will handle both updating and creating a new item.
	 *
	 * @param {Object} allTabsData - Internal tab data object where keys are tab IDs and values are internal tabs data.
	 * @param {MouseEvent} event - Mouse click event for clicked action button DOM element.
	 * @param {'create'|'update'} actionId - ID of the clicked action.
	 * @return {Promise<*>}
	 */
	activate(allTabsData, event, actionId) {
		const {
			cappingItemName, updateCappingItemAction, openDialogAction, closeDialogAction, addSuccessMessageAction
		} = this.props;

		return new Promise(resolve => {
			/** @type {CappingItemDataObject} */
			const item = get(allTabsData, 'MainTab');
			
			const dialogGUIID = openDialogAction('', ConfirmDialog, {
				message: this.t('confirm_activate', '', '', {name: cappingItemName}),
				supportHtml: true,
				onYes: () => {
					this.executeAbortableAction(
						updateCappingItemAction,
						getString(item, 'id', '', true),
						{...item, active: true}
					)
						.then(response => {
							if (response) addSuccessMessageAction(this.t('activate_success_msg'));
							closeDialogAction(dialogGUIID);
							return response;
						})
						.finally(() => resolve());
				},
				onNo: () => {
					closeDialogAction(dialogGUIID);
					resolve();
				}
			}, {
				id: 'item-activate-dialog',
				closeOnEscape: true,
				closeOnClickOutside: true,
				hideCloseBtn: true,
				maxWidth: 600
			});
			this.setOption(
				'dialogsToCloseOnUnmount',
				trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
			);
		});
	}
	
	/**
	 * De-activate item
	 * @note This method will handle both updating and creating a new item.
	 *
	 * @param {Object} allTabsData - Internal tab data object where keys are tab IDs and values are internal tabs data.
	 * @param {MouseEvent} event - Mouse click event for clicked action button DOM element.
	 * @param {'create'|'update'} actionId - ID of the clicked action.
	 * @return {Promise<*>}
	 */
	deactivate(allTabsData, event, actionId) {
		const {
			cappingItemName, updateCappingItemAction, openDialogAction, closeDialogAction, addSuccessMessageAction
		} = this.props;

		return new Promise(resolve => {
			/** @type {CappingItemDataObject} */
			const item = get(allTabsData, 'MainTab');

			const dialogGUIID = openDialogAction('', ConfirmDialog, {
				message: this.t('confirm_deactivate', '', '', {name: cappingItemName}),
				supportHtml: true,
				onYes: () => {
					this.executeAbortableAction(
						updateCappingItemAction,
						getString(item, 'id', '', true),
						{...item, active: false}
					)
						.then(response => {
							if (response) addSuccessMessageAction(this.t('deactivate_success_msg'));
							closeDialogAction(dialogGUIID);
							return response;
						})
						.finally(() => resolve());
				},
				onNo: () => {
					closeDialogAction(dialogGUIID);
					resolve();
				}
			}, {
				id: 'item-deactivate-dialog',
				closeOnEscape: true,
				closeOnClickOutside: true,
				hideCloseBtn: true,
				maxWidth: 600
			});
			this.setOption(
				'dialogsToCloseOnUnmount',
				trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
			);
		});
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
ItemPopup.propTypes = {
	// Flag that specifies if this popup is for creating a new item
	// @note This prop can be dynamically changed.
	isNew: PropTypes.bool,
	// Function that will redirect to item URL
	redirectToItem: PropTypes.func, // Arguments: {string} - Item id to redirect to.
	// Function that will redirect to the base URL of the page
	redirectToBase: PropTypes.func, // Arguments: no arguments
	
	// Actions
	// @param {ManageDocumentsItemDataObject} item - Item to delete. Full item is needed to access the name displayed in
	// delete confirmation message.
	// @return {Promise<void>}
	deleteItemAction: PropTypes.func,

	// Events
	onClose: PropTypes.func,
	onGlobalAction: PropTypes.func,
	onTabAction: PropTypes.func,
};

export default connect(
	mapStateToProps, getPageActions(actions), null, {forwardRef: true}
)(ItemPopup);