import {getArray, getString, isset} from 'Core/helpers/data';
import {input} from 'Tags/dataMap';
import {ioJsonAction} from 'Core/store/actions/io';
import {isCustomFieldTag, getTagName} from 'Tags/helpers';
import {fetchCustomerCustomFieldsListAction} from 'Pages/apps/default/customerCustomFields/actions';
import {find, get} from 'lodash';
import {tag_type_prefix_custom_field} from 'Config/app';

/**
 * Fetch tags that can be used for customer message personalization
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {CampaignMessageContentType} [messageType] - Message type to get tags for.
 * @param {string} [clientId] - DB ID of the client (used by super admin).
 * @param {boolean} [useUndefined] - Flag that specifies if undefined will be returned if IO response is undefined (IO 
 * error or request was aborted). 
 * @return {function(*): Promise<TagDataObject[]>}
 */
export const fetchTagsAction = (abortCallback, messageType, clientId, useUndefined) => dispatch => {
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'campaign/fetch-possible-tags-for-message-type',
		{messageType, clientId},
	)(dispatch)
		// Add tag description and data type properties for customer custom field tags
		.then(async res => {
			const tags = getArray(res, 'data');
			
			if (tags.length > 0 && tags.some(tag => isCustomFieldTag(getString(tag, 'tag')))) {
				const customFieldsRes = await fetchCustomerCustomFieldsListAction(abortCallback)(dispatch);
				/** @type {CustomerCustomFieldsListItemDataObject[]} */
				const customFields = getArray(customFieldsRes, 'data');
				
				return tags.map(tag => {
					/** @type {CustomerCustomFieldsListItemDataObject} */
					const customField = find(customFields, {name: getTagName(tag?.tag, tag_type_prefix_custom_field)});
					return ({
						...tag, 
						tagDescription: get(customField, 'description'), 
						tagDataType: get(customField, 'dataType')
					});
				});
			}
			
			return (useUndefined && !isset(res) ? undefined : tags);
		})
		// Map external data to local data
		.then(tags => useUndefined && !isset(tags) ? undefined : tags.map(i => input(i)));
};
