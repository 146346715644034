import styles from "../../index.module.css";
import "../../../../index.css";

import React from "react";
import BaseComponent, {executeComponentCallback} from "Core/components/BaseComponent";
import {connect} from "react-redux";
import {getGlobalActions} from "Core/helpers/redux";
import * as pageConfig from "../../config";
import * as campaignPageConfig from "../../../../config";
import {sortBy} from "lodash";
import {getArray, getString, isset} from "Core/helpers/data";
import {Tooltip} from "react-tippy";
import Icon from "Core/components/display/Icon";
import NumberLabel from "Core/components/display/NumberLabel";
import Label from "Core/components/display/Label";
import DateLabel from "Core/components/display/DateLabel";
import {icon_font_delete_symbol, icon_font_error_symbol, messages_default_auto_hide_after} from "Config/app";
import PropTypes from "prop-types";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import {reducerStoreKey} from "../../../../reducer";
import {selectors} from "Core/store/reducers";
import Spinner from "Core/components/display/Spinner";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {Object} state - Redux entire store state.
 * @param {Object} ownProps - Components own props.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = (state, ownProps) => ({
	isGeneratingForDefinition: selectors[reducerStoreKey].isMessageDefinitionGeneratingCustomerMessages(
		state, getString(ownProps, 'data.id')
	),
});

class MessageDefinition extends BaseComponent {
	constructor(props) {
		super(props, {
			translationPath: pageConfig.translationPath,
			optimizedUpdate: true,
			optimizedUpdateIgnoreProps: ['onEdit', 'onDelete'],
		});
		
		// Dialog methods
		this.openErrorDialog = this.openErrorDialog.bind(this);
	}

	
	// Dialog methods ---------------------------------------------------------------------------------------------------
	/**
	 * Open the error message dialog
	 */
	openErrorDialog() {
		const {addErrorMessageAction, errors} = this.props;
		
		addErrorMessageAction(
			(
				<ul>
					{getArray(errors).map((mdError, idx) =>
						<Label
							key={idx}
							element="li"
							content={this.t(
								mdError.errorType,
								'AppsSection.DefaultApp.projectPages.CampaignPage.ItemPage.errors.types',
								'',
								{field: this.t(
									mdError.errorValue,
									'AppsSection.DefaultApp.projectPages.CampaignPage.ItemPage.errors.fields'
								)}
							)}
							supportHtml={true}
						/>
					)}
				</ul>
			),
			messages_default_auto_hide_after,
			'',
			undefined,
			undefined,
			undefined,
			true,
			{
				id: 'publish-campaign-message-definition-error-dialog',
				closeOnEscape: true,
				closeOnClickOutside: false,
				hideCloseBtn: true,
			},
			{
				title: this.t(
					'message_definition_dialog_title',
					'AppsSection.DefaultApp.projectPages.CampaignPage.ItemPage.errors'
				),
				allowHtml: true,
				alignContent: 'left'
			}
		)
	}
	
	
	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Method that should return true if component can be rendered or false otherwise
	 * @return {boolean} True if component can be rendered or false otherwise.
	 */
	canRender() {
		const {data} = this.props;
		return (isset(data) && data !== null);
	}

	render() {
		// Do not render component if 'canRender' returns false
		if (!this.canRender()) return null;
		
		const {className, readOnly, errors, isGeneratingForDefinition} = this.props;
		/** @type {MessageDefinitionDataObject} */
		const data = this.getProp('data');
		/** @type {MessageDefinitionChannelDataObject[]} */
		const channels = sortBy(getArray(data, 'channels'), ['priority']);

		return (
			data ?
				<div
					id={this.getDomId()}
					className={
						`${styles['ping']} ${styles[`status${data.status}`]} ${className} ` +
						`${readOnly ? styles['readOnly'] : ''}`
					}
					onClick={e => {
						if (isset(this.props.onEdit)) executeComponentCallback(this.props.onEdit, data, e);
					}}
				>
					<div className={`${styles['pingInner']}`}>
						<div className={`${styles['basicInfo']}`}>
							<div className={styles['identifier']}>
								{isset(data.ordinal) && data?.ordinal !== null ?
									<Label 
										element="span" 
										content={
											`${this.tt('ID', 'messageDefinition')}${data.ordinal}: ` +
											`${this.tt(getString(data, 'status'),'messageDefinition.status')}`
										}
									/>
									: null
								}
							</div>
							<span className={styles['count']}>
								<NumberLabel number={data.messageCount ? data.messageCount : null} defaultOutput="—"/>&nbsp;{
								data.interval ?
									<Label
										content={this.t(
											data.interval, `${campaignPageConfig.translationPath}.interval`
										)}
									/>
									: "—"
								}
							</span>
						</div>
						<div className={`${styles['channels']}`}>
							{channels.filter(c => !!c.channelId).map((c, idx) =>
								<span key={c.channelId} className={`${styles['channel']}`}>
									<Label
										element="span"
										elementProps={{className: 'tag'}}
										content={c.channelName}
										tooltip={idx === 0 ? this.tt('mainChannelField', 'MessageDefinitionPopup.ChannelsTab'):''}
									/>
									{
										idx <= (channels.length - 2) ?
											<Icon symbol="chevron-right" className={styles['arrow']} />
											: null
									}
								</span>
							)}
						</div>
						<div className={`${styles['date']}`}>
							<DateLabel inputDate={data.startDate} defaultOutput="—" />
						</div>
					</div>
					<div className={`${styles['actions']}`}>
						{isset(this.props.onDelete) ?
							<Tooltip
								tag="div"
								title={!readOnly ? this.t('Delete', 'general') : ''}
								size="small"
								position="top-center"
								arrow={true}
								interactive={false}
							>
								<Button
									className="hover-as-action"
									icon={icon_font_delete_symbol}
									displayType={BUTTON_DISPLAY_TYPE.NONE}
									displayStyle={BUTTON_STYLE.SUBTLE}
									onClick={async e => {
										e.stopPropagation();
										executeComponentCallback(this.props.onDelete, data, e);
									}}
									disabled={readOnly}
								/>
							</Tooltip>
							: null
						}

						{!Array.isArray(errors) || errors.length === 0 ? null :
							<Tooltip
								tag="div"
								title={this.tt('message_definition_error_tooltip', 'messageDefinition')}
								size="small"
								position="top-center"
								arrow={true}
								interactive={false}
							>
								<Button
									icon={icon_font_error_symbol}
									displayType={BUTTON_DISPLAY_TYPE.SOLID}
									displayStyle={BUTTON_STYLE.ERROR}
									onClick={e => {
										e.stopPropagation();
										this.openErrorDialog();
									}}
								/>
							</Tooltip>
						}

						{!isGeneratingForDefinition ? null :
							<Tooltip
								tag="div"
								title={this.tt(
									'generating_tooltip',
									'MessageDefinitionPopup.RecipientsTab.customer_messages_section'
								)}
								size="small"
								position="top-center"
								arrow={true}
								interactive={false}
							>
								<div className="center-content-vertical">
									<Spinner color="var(--highlight-color)" size={12} weight={6} linear={true} slow={true} />
								</div>
							</Tooltip>
						}
					</div>
				</div>
				: null
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
MessageDefinition.propTypes = {
	// Wrapper element's id
	id: PropTypes.string,
	// Wrapper element's CSS class name
	className: PropTypes.string,
	// Message definition
	// @type {MessageDefinitionDataObject}
	data: PropTypes.object,
	// Flag that specifies if component should be rendered in read-only mode
	readOnly: PropTypes.bool,
	// Message definition errors
	errors: PropTypes.array,
	// Function that will be called when non deleted message definition component is clicked
	// @type {Function<MessageDefinitionDataObject: string, MouseEvent>}
	onEdit: PropTypes.func,
	// Function that will be called when delete button is clicked
	// @type {Function<MessageDefinitionDataObject: string, MouseEvent>}
	onDelete: PropTypes.func,
};

/**
 * Define component default values for own props
 */
MessageDefinition.defaultProps = {
	id: '',
	className: '',
	readOnly: false,
};

export default connect(mapStateToProps, getGlobalActions())(MessageDefinition);