import styles from "./index.module.css";
import "./index.css";

import React from "react";
import DialogDataComponent from "Core/components/DialogDataComponent";
import {connect} from "react-redux";
import {CampaignWizardDataObject} from 'Components/dialogs/CampaignWizardDialog/dataObjects';
import {getGlobalActions} from 'Core/helpers/redux';
import FormWrapper, {FormField} from 'Core/components/advanced/FormWrapper';
import {RADIO_INPUT_LAYOUT as FORM_FIELD_POSITION} from 'Core/components/input/RadioInput';
import ProjectSelectInput from 'Components/input/ProjectSelectInput';
import TextInput from 'Core/components/input/TextInput';
import TextareaInput from 'Core/components/input/TextareaInput';
import {FORM_FIELD_LABEL_POSITION} from 'Core/components/advanced/FormWrapper/FormField';
import DateInput from 'Core/components/input/DateInput';
import PropTypes from 'prop-types';
import {
	CAMPAIGN_MESSAGE_CONTENT_TYPE,
	CAMPAIGN_MESSAGE_CONTENT_TYPE_ICONS,
	CAMPAIGN_MESSAGE_CONTENT_TYPES
} from 'Pages/apps/default/projectPages/campaign/const';
import Label, {LABEL_ICON_POSITION} from 'Core/components/display/Label';
import {icon_font_close_symbol, icon_font_create_symbol, icon_font_loading_symbol} from 'Config/app';
import {isMacintosh} from 'Core/helpers/system';
import {BUTTON_STYLE} from 'Core/components/display/Button';
import {getString, isset} from 'Core/helpers/data';
import {CAMPAIGN_WIZARD_PAGE, CAMPAIGN_WIZARD_PAGES} from 'Components/dialogs/CampaignWizardDialog/const';
import Channels from 'Components/advanced/Channels';
import {get} from 'lodash';
import {ChannelDataObject} from 'Components/advanced/Channels/dataObjects';
import {selectors} from "Core/store/reducers";
import DataValueValidation, {VALIDATION_FIELD_TYPE, ValidationConstraintObject} from 'Core/validation';
import {startOfDay} from 'date-fns';
import {getAppDateString} from 'Core/helpers/datetime';
import {LOCALE_DATE_FORMAT_NAME} from 'Core/const/locale';
import {translate} from 'Core/i18n';

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	isMobileBreakpoint: selectors.breakpoint.isMobileBreakpoint(state),
});

class CampaignWizardDialog extends DialogDataComponent {
	constructor(props) {
		super(props, {
			data: new CampaignWizardDataObject(
				get(props, 'project.id'),
				(
					translate(getString(props, 'messageType'), 'CampaignWizardDialog.new_title') + ' (' +
					getAppDateString(new Date(), LOCALE_DATE_FORMAT_NAME.SHORT) + ')'
				), 
				undefined, 
				undefined, 
				undefined, 
				[new ChannelDataObject()],
			),
			/** @type {CampaignWizardPage} */
			currentPage: CAMPAIGN_WIZARD_PAGE.INTRO,
			/** @type {boolean} */
			projectListLoading: true,
			/** @type {boolean} */
			showProjectSelect: false,
			/** @type {boolean} */
			skipChannelsPage: false,
		}, {
			translationPath: 'CampaignWizardDialog',
			domPrefix: 'campaign-wizard-dialog-content',
			disableLoad: true,
		});
		
		// Refs
		this.channelsRef = null;
		
		// Page methods
		this.getPrevPage = this.getPrevPage.bind(this);
		this.getNextPage = this.getNextPage.bind(this);
		this.goToPrevPage = this.goToPrevPage.bind(this);
		this.goToNextPage = this.goToNextPage.bind(this);
		this.goToPage = this.goToPage.bind(this);

		// Validation methods
		this.validatePublish = this.validatePublish.bind(this);
		
		// Render methods
		this.renderPageTitle = this.renderPageTitle.bind(this);
		this.renderPage_INTRO = this.renderPage_INTRO.bind(this);
		this.renderPage_GENERAL = this.renderPage_GENERAL.bind(this);
		this.renderPage_CHANNELS = this.renderPage_CHANNELS.bind(this);
	}
	
	
	// Page methods -----------------------------------------------------------------------------------------------------
	/**
	 * Get wizard page previous to the current one
	 * @return {CampaignWizardPage|undefined} Wizard page previous to the current one or undefined if there is no 
	 * previous page (currently on the first page).
	 */
	getPrevPage() {
		const {currentPage} = this.state;
		return get(CAMPAIGN_WIZARD_PAGES, `[${CAMPAIGN_WIZARD_PAGES.indexOf(currentPage) - 1}]`);
	}

	/**
	 * Get wizard page next to the current one
	 * @note This page will be skipped if there is only one available channel with one sender.
	 * @return {CampaignWizardPage|undefined} Wizard page next to the current one or undefined if there is no next page 
	 * (currently on the last page).
	 */
	getNextPage() {
		const {currentPage, skipChannelsPage} = this.state;
		let result = get(CAMPAIGN_WIZARD_PAGES, `[${CAMPAIGN_WIZARD_PAGES.indexOf(currentPage) + 1}]`);
		
		// Skip the channels page if there is only one available channel with one sender
		if (result === CAMPAIGN_WIZARD_PAGE.CHANNELS && skipChannelsPage) return undefined;
		
		return result;
	}
	
	/**
	 * Go to any wizard page
	 * @note Currently opened wizard page will be unmounted and the specified wizard page will be mounted.
	 * 
	 * @param {CampaignWizardPage} page
	 * @return {Promise<Object>} Promise that resolves to entire component local state after state is updated. 
	 */
	goToPage(page) {
		return this.setState({currentPage: page});
	}
	
	/**
	 * Go to the previous wizard page
	 * @return {Promise<Object>} Promise that resolves to entire component local state after state is updated.
	 */
	goToPrevPage() {
		const prevPage = this.getPrevPage();
		return (!!prevPage ? this.goToPage(prevPage) : Promise.resolve(this.state));
	}

	/**
	 * Go to the next wizard page
	 * @return {Promise<Object>} Promise that resolves to entire component local state after state is updated.
	 */
	goToNextPage() {
		const nextPage = this.getNextPage();
		return (!!nextPage ? this.goToPage(nextPage) : Promise.resolve(this.state));
	}
	
	
	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render dialog action buttons used by data dialogs
	 * @note Every dialog that is used to manipulate some data should use these action buttons on the bottom of the main
	 * render method. Standard buttons include create, save, delete, restore and close and they will be rendered
	 * depending on dialog type and available events (isNew, isRestore, onDelete, ...).
	 *
	 * @param {string|null} [createLabel] - Label used for create button. Default value will be loaded from translation file.
	 * @param {string|null} [closeLabel] - Label used for close button. Default value will be loaded from translation file.
	 * @param {string|null} [closeIcon] - Optional icon used for close button.
	 * @param {boolean} [hideClose=false] - If true, close button will not be rendered. This is useful for confirm
	 * dialogs.
	 * @return {*} Action buttons JSX to use in the main render method.
	 */
	renderDataActionButtons({closeLabel = undefined, closeIcon = undefined} = {}, hideClose = false) {
		const {currentPage, projectListLoading} = this.state;
		const autofocus = this.getOption('autoFocusActionButtons');

		let buttons = [];

		// Close/cancel button
		if (isMacintosh() && !hideClose) buttons.push({
			style: BUTTON_STYLE.DEFAULT,
			label: (closeLabel ? closeLabel : this.t('Close', 'general')),
			icon: (isset(closeIcon) && closeLabel !== null ? closeIcon : icon_font_close_symbol),
			onClick: this.close,
		});

		// Got to previous page button
		if (!!this.getPrevPage()) buttons.push({
			style: BUTTON_STYLE.DEFAULT,
			label: <Label icon="arrow-left" iconPosition={LABEL_ICON_POSITION.LEFT} content={this.t('goToPrevPageBtn')} />,
			onClick: this.goToPrevPage,
		});

		// Close/cancel button
		if (!isMacintosh() && !hideClose) buttons.push({
			style: BUTTON_STYLE.DEFAULT,
			label: (closeLabel ? closeLabel : this.t('Close', 'general')),
			icon: (isset(closeIcon) && closeLabel !== null ? closeIcon : icon_font_close_symbol),
			onClick: this.close,
		});

		// Go to next page button
		if (!!this.getNextPage()) buttons.push({
			style: BUTTON_STYLE.ACTION,
			label: (
				<Label 
					icon={projectListLoading ? icon_font_loading_symbol : "arrow-right"} 
					iconPosition={LABEL_ICON_POSITION.RIGHT} 
					iconSpin={projectListLoading}
					content={this.t('goToNextPageBtn')} 
				/>
			),
			onClick: this.goToNextPage,
			disabled: (
				(currentPage === CAMPAIGN_WIZARD_PAGE.INTRO && !this.getValue('projectId')) ||
				(currentPage === CAMPAIGN_WIZARD_PAGE.GENERAL && !this.getValue('name'))
			),
			autofocus,
		});

		// Create button
		if (!this.getNextPage()) buttons.push({
			style: BUTTON_STYLE.ACTION,
			label: this.t('Create', 'general'),
			icon: icon_font_create_symbol,
			onClick: this.save,
		});

		return this.renderActionButtons(buttons);
	}
	
	
	// Validation methods
	/**
	 * Validate data for publishing
	 * @return {boolean}
	 */
	validatePublish() {
		const dataValidation = new DataValueValidation();
		const dataToValidate = this.getData();
		const startOfToday = startOfDay(new Date());
		const startDate = dataToValidate.startDate ? startOfDay(dataToValidate.startDate) : null;
		
		dataValidation.addRule('startDate', 'required', 'datetime');
		dataValidation.addRule('endDate', 'datetime');
		dataValidation.addConstraint('startDate', new ValidationConstraintObject(
			'min', startOfToday, VALIDATION_FIELD_TYPE.DATE,
		));
		dataValidation.addConstraint('endDate', new ValidationConstraintObject(
			'min', startDate, VALIDATION_FIELD_TYPE.DATE, {ignoreEmpty: true}
		));

		// Run validation
		const validationErrors = dataValidation.run(dataToValidate);
		
		if (validationErrors) this.setValidationErrors('', validationErrors).then();
		else this.clearValidationErrors().then();
		return !validationErrors;
	}
	

	/**
	 * Render wizard page title
	 * @return {JSX.Element}
	 */
	renderPageTitle() {
		const {currentPage} = this.state;
		
		return (
			<>
				{this.hasTranslationPath(`${this.getOption('translationPath')}.page.${currentPage}.title`) ?
					<Label
						element="div"
						elementProps={{className: styles['pageTitle']}}
						content={this.tt('title', `page.${currentPage}`)}
						supportHtml={true}
					/>
					: null
				}
				{this.hasTranslationPath(`${this.getOption('translationPath')}.page.${currentPage}.desc`) ?
					<Label
						element="div"
						elementProps={{className: styles['pageDesc']}}
						content={this.tt('desc', `page.${currentPage}`)} 
						supportHtml={true}
					/>
					: null
				}
			</>
		);
	}
	
	/**
	 * Render campaign wizard intro page
	 * @return {JSX.Element}
	 */
	renderPage_INTRO() {
		const {project} = this.props;
		const {currentPage, showProjectSelect} = this.state;
		
		return (
			<div className={`intro-page ${currentPage !== CAMPAIGN_WIZARD_PAGE.INTRO ? 'no-display' : ''}`}>
				{this.renderPageTitle()}
				<FormWrapper 
					key="intro-page" 
					className={`${styles['pageForm']} ${!showProjectSelect || !!project ? 'no-display' : ''}`}
				>
					<FormField
						required={true}
						label={this.t('projectIdField')}
						labelPosition={FORM_FIELD_POSITION.ALIGNED}
						errorMessages={this.getValidationErrors('projectId')}
					>
						<ProjectSelectInput
							editableProjectsOnly={true}
							useGlobalProjectList={false}
							autoSelectSingleProject={!project}
							isClearable={false}
							value={this.getValue('projectId')}
							onChange={v => this.handleValueChange('projectId', v)}
							onLoadingStart={() => this.setState({projectListLoading: true})}
							onLoadingEnd={singleProject => this.setState({
								projectListLoading: false, 
								showProjectSelect: !singleProject
							})}
						/>
					</FormField>
				</FormWrapper>
			</div>
		);
	}

	/**
	 * Render campaign wizard page containing campaign's general data
	 * @return {JSX.Element}
	 */
	renderPage_GENERAL() {
		const {currentPage} = this.state;
		
		return (
			<div className={`general-page ${currentPage !== CAMPAIGN_WIZARD_PAGE.GENERAL ? 'no-display' : ''}`}>
				{this.renderPageTitle()}
				<FormWrapper key="general-page" className={styles['pageForm']}>
					<FormField
						required={true}
						label={this.t('nameField')}
						labelPosition={FORM_FIELD_POSITION.ALIGNED}
						errorMessages={this.getValidationErrors('name')}
					>
						<TextInput
							isFast={false}
							name="name"
							value={this.getValue('name')}
							onChange={this.handleInputChange}
						/>
					</FormField>

					<FormField
						className="multiline-form-field"
						label={this.t('descriptionField')}
						labelPosition={FORM_FIELD_POSITION.ALIGNED}
						errorMessages={this.getValidationErrors('description')}
					>
						<TextareaInput
							rows={3}
							name="description"
							value={this.getValue('description')}
							onChange={this.handleInputChange}
						/>
					</FormField>

					<FormField
						labelPosition={FORM_FIELD_LABEL_POSITION.ALIGNED}
						label={this.t('startDateField')}
						errorMessages={this.getValidationErrors('startDate')}
					>
						<DateInput
							openToDate={this.getValue('startDate') ? this.getValue('startDate') : new Date()}
							value={this.getValue('startDate')}
							onChange={d => this.handleValueChange('startDate', d)}
						/>
					</FormField>

					<FormField
						label={this.t('endDateField')}
						labelPosition={FORM_FIELD_LABEL_POSITION.ALIGNED}
						errorMessages={this.getValidationErrors('endDate')}
					>
						<DateInput
							openToDate={this.getValue('endDate') ? this.getValue('endDate') : new Date()}
							value={this.getValue('endDate')}
							onChange={d => this.handleValueChange('endDate', d)}
						/>
					</FormField>
				</FormWrapper>
			</div>
		);
	}
	
	/**
	 * Render campaign channels wizard page
	 * @return {JSX.Element}
	 */
	renderPage_CHANNELS() {
		const {messageType, supportPublish} = this.props;
		const {currentPage} = this.state;
		
		return (
			<div className={`channels-page ${currentPage !== CAMPAIGN_WIZARD_PAGE.CHANNELS ? 'no-display' : ''}`}>
				{this.renderPageTitle()}
				<Channels
					className={`channels-form ${styles['channelsForm']}`}
					projectId={this.getValue('projectId')}
					autoSelectSingleChannel={true}
					autoSelectSingleChannelMessageTypeFilter={messageType}
					data={this.getValue('channels')}
					onChange={c => this.handleValueChange('channels', c)}
					onLoadingEnd={(singleChannel, singleSender) => this.setState({
						skipChannelsPage: (
							singleChannel && 
							(singleChannel.messageType === CAMPAIGN_MESSAGE_CONTENT_TYPE.PUSH_NOTIFICATION || singleSender)
						)
					})}
					supportPublish={supportPublish}
					ref={node => { this.channelsRef = node; }}
				/>
			</div>
		);
	}
	
	/** @inheritDoc */
	save() {
		const {supportPublish} = this.props;
		
		if (supportPublish) {
			// If component's data is not valid navigate to the general page because only general page has data that needs to 
			// be validated
			if (!this.validatePublish()) this.goToPage(CAMPAIGN_WIZARD_PAGE.GENERAL).then();
			// If component's data is valid, validate channels component
			else {
				if (this.channelsRef) {
					// Call the save method if channels are valid
					if (this.channelsRef.validatePublish()) super.save();
				} else {
					// Call the save method if channels component ref does not exist (should ot happen)
					super.save();
				}
			}
		} else {
			super.save();
		}
	}

	render() {
		const {messageType, isMobileBreakpoint} = this.props;
		const {currentPage} = this.state;

		return this.renderDialog(
			this.renderCustomIconTitle(
				CAMPAIGN_MESSAGE_CONTENT_TYPE_ICONS[messageType],
				<Label content={this.tt(messageType, 'new_title')} />,
				`title ${styles['title']}`
			),
			(
				<div id={this.getDomId()} className={`${this.getOption('domPrefix')} ${styles['wrapper']}`}>
					{/* All pages are rendered in order to determine if channel page should be skipped */}
					{this.renderPage_INTRO()}
					{this.renderPage_GENERAL()}
					{this.renderPage_CHANNELS()}
					
					{this.hasTranslationPath(`${this.getOption('translationPath')}.page.${currentPage}.notice`) ?
						<Label
							element="small"
							elementProps={{className: `notice info ${styles['pageNotice']}`}}
							icon="info-circle"
							iconClassName="notice-icon"
							iconPosition={LABEL_ICON_POSITION.NONE}
							content={this.tt('notice', `page.${currentPage}`)}
							supportHtml={true}
						/>
						: null
					}
				</div>
			), 
			{closeLabel: this.t('Cancel', 'general')},
			isMobileBreakpoint,
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
CampaignWizardDialog.propTypes = {
	// Message type to create a campaign for 
	messageType: PropTypes.oneOf(CAMPAIGN_MESSAGE_CONTENT_TYPES).isRequired,
	// Preselected project
	// @note If specified, project select input will not be rendered on the intro page.
	// @type {ProjectListItemDataObject}
	project: PropTypes.object,
	// Flag that specifies if data will be validated for publishing before saving.
	supportPublish: PropTypes.bool,
};

/**
 * Define component default values for own props
 */
CampaignWizardDialog.defaultProps = {
	
};

export default connect(mapStateToProps, getGlobalActions())(CampaignWizardDialog);