/**
 * Abstract app component used to create app main components (like DefaultApp for example) with support for projects
 */
import AppSectionComponent from 'Core/components/AppSectionComponent';
import {getBool, getObject} from "Core/helpers/data";
import {AppPageRoute} from "Core/App";
import AppPageRouteWithProjects from 'Components/router/AppPageRouteWithProjects';

class AppSectionComponentWithProjects extends AppSectionComponent {
	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render app app pages
	 * @return {*}
	 */
	renderPages() {
		const appPages = this.getOption('appPages');
		return (appPages.map((page, idx) =>
			getBool(page, 'isProjectPage') ?
				AppPageRouteWithProjects({
					key: idx,
					page,
					...getObject(page, 'routerOptions')
				})
				:
				AppPageRoute({
					key: idx,
					page,
					...getObject(page, 'routerOptions')
				})
		));
	}
}

// Type definitions
/**
 * @typedef {Object} AppComponentOptions
 * @property {string} [translationPath] - Path inside the translation JSON file where component translations are
 * defined. This will be passed form the constructor. Specify it manually only if you need to overwrite the default
 * functionality.
 * @property {string} [domPrefix=] - Prefix used for component's main DOM element. This is used in methods like 
 * 'getDomId'. This will be passed form the constructor. Specify it manually only if you need to overwrite the default 
 * functionality.
 * @property {number} [domManipulationIntervalTimeout=0] - Timeout in ms (milliseconds) for DOM manipulation interval.
 * If less than zero DOM manipulation interval will be disabled.
 * @property {boolean} [optimizedUpdate=false] - Flag that determines if set component will skip updates if both props
 * and state are equal.
 * @property {string[]} [optimizedUpdateIgnoreProps] - List of prop names that will be ignored during optimization if
 * 'optimizedUpdate' is true. Use '*' array item for all props.
 * @property {string[]} [optimizedUpdateIncludeState] - List of state values that will be included in optimization if
 * 'optimizedUpdate' is true. Use '*' array item for all state fields.
 * @property {boolean} [updateOnSkinChange=false] - Flag that specifies if component will update when app skin has been
 * changes (for example from light to dark).
 * @property {string[]} [dialogsToCloseOnUnmount=[]] - List of dialog GUI IDs of the dialogs that should be closed when
 * page component unmounts.
 * @property {Object} [appConfig] - Imported app config. This will be passed form the constructor. Specify it manually 
 * only if you need to overwrite the default functionality.
 * @property {Object[]} [appPages=[]] - Imported app pages.
 * @property {boolean} [checkLogin=false] - Flag that specifies if login is required to access this app.
 * @property {boolean} [autoLogout=false] - Flag that specifies if user will be redirected to login page on logout on 
 * any browser tab.
 */

export default AppSectionComponentWithProjects;