/**
 * Personalization tag data object
 */
export class TagDataObject {
	/**
	 * @param {string} tag - Actual tag (with prefix and suffix).
	 * @param {?string|undefined} [tagDescription] - Tag's description.
	 * @param {?CustomerCustomFiledType|''|undefined} [tagDataType] - Tag's data type.
	 * @param {number} length - Length of the tag value in the delivered message.
	 * @param {TagLengthPrecision} lengthPrecision - Specifies the precision of the 'length' param since some tags have
	 * fixed length (like unsubscribe links) and others have dynamic length (like first name, last name, etc.).
	 */
	constructor(tag, tagDescription, tagDataType, length, lengthPrecision) {
		this.tag = tag;
		this.tagDescription = tagDescription;
		this.tagDataType = tagDataType;
		this.length = length;
		this.lengthPrecision = lengthPrecision;
	}
}